import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalSwiper from "../Shop/ModalSwiper";
import Heartsvgfile from "../Shop/HeartSvg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../assert/css/modal.css'

import {
  addToCart,
  addToWishList,
  getImageVarients,
  getProductImages,
  getSingleProduct,
} from "../../service/apiService";
import { toast } from "react-toastify";

const ModalProduct = ({ showModal, handleCloseModal }) => {
  const navigate = useNavigate();
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const location = useLocation();
  const { id } = location.state || {};
  const { preorder } = location.state || {};
  const token = localStorage.getItem("token");
  const [productVarientsData, setProductVarients] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [productVarientId, setProductVarientId] = useState(null);
  const [isHaveVarient, setIsHaveVarient] = useState(0);
  const [pincode, setPincode] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [productAlldata, setProductAlldata] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productImages, setProductImages] = useState([]);

  const productVarients = async () => {
    const response = await getImageVarients(id);
    const variants = response.data || [];
    setProductVarients(variants);
    setIsHaveVarient(variants.length > 0 ? 1 : 0);
    if (variants.length > 0) {
      setProductVarientId(variants[0].variant_map_id);
    }
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    setSelectedSize(value);
    setProductVarientId(value);
  };

  const handleAddToCart = (productId) => {
    if (isHaveVarient === 1 && !selectedSize) {
      toast.error("Please select a size before adding to the cart.");
      return;
    }
    const variantId = selectedSize || productVarientsData[0]?.variant_map_id;
    const response = addToCart(token, productId, variantId);
    response
      .then((data) => {
        if (data.status === 400) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.data.error);
        } else {
          toast.error("Please Login First");
        }
      });
  };

  const handleWishList = async (productId) => {
    const wishListResponse = await addToWishList(token, productId);
    if (wishListResponse.status === 401) {
      toast.error(wishListResponse.message);
    } else if (wishListResponse === 403) {
      toast.info("Product Already in wishlist");
    } else if (wishListResponse.status_code === 200) {
      toast.success(wishListResponse.message);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (pincode.length === 6) {
      setIsValid(true);
      setMessage(
        "Your Product will be delivered to your Pincode within 6 to 7 days"
      );
      setTimeout(() => {
        setMessage("");
        setPincode("");
      }, 7000);
    } else {
      setIsValid(false);
      setMessage("Pincode must be 6 characters long");
    }
  };

  useEffect(() => {
    if (id) {
      getSingleProductDetails();
      getProductDetails();
      productVarients();
    }
    setSelectedSize(null);
    window.scrollTo(0, 0);
  }, [id, isHaveVarient]);

  const getSingleProductDetails = async () => {
    try {
      const response = await getSingleProduct(id);
      setProductData(response.data);
    } catch (error) { }
  };

  const getProductDetails = async () => {
    const response = await getProductImages(id);
    setProductImages(response.data || []);
  };

  useEffect(() => {
    if (productImages.length >= 0 && productData) {
      newFunction(productImages, productData);
    }
  }, [productImages, productData]);

  const newFunction = (productImages, productData) => {
    const updatedProductImages = [
      { image_path: productData.thumnail_image },
      ...productImages,
    ];
    setProductAlldata(updatedProductImages);
  };

  const defaultStyle = {
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "16px",
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #ccc",
    transition: "background-color 0.3s ease, color 0.3s ease",
  };

  const selectedStyle = {
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "16px",
    backgroundColor: "#000",
    color: "#fff",
    border: "1px solid #000",
    transition: "background-color 0.3s ease, color 0.3s ease",
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="quick-view-modal "
    >
      <div className="d-flex justify-content-end me-3 mt-2">
        <button
          type="button"
          onClick={handleCloseModal}
          className="btn-close  "
          aria-label="Close"
        ></button>
      </div>

      <Modal.Body
      className="w-100">
        <div className="row g-xl-4  g-3">
          <div className="col-xl-6 col-md-6">
            <div className="dz-product-detail mb-0">
              <div className="swiper-btn-center-lr">
                <ModalSwiper productAlldata={productAlldata} />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="dz-product-detail style-2 ps-xl-3 ps-0 pt-2 mb-0">
              <div className="dz-content">
                <div className="dz-content-footer">
                  <div
                    className={`${productData.discount_percentage !== 0
                      ? "dz-content-start"
                      : "mb-2"
                      }`}
                  >
                    {productData.discount_percentage !== 0 ? (
                      <span className="badge bg-secondary mb-2">
                        SALE {productData.discount_percentage} % Off
                      </span>
                    ) : null}

                    <h4 className="title mb-1">{productData.product_name}</h4>
                    {productData.discount_price === 0 ? (
                      <div className="me-3">
                        <span className="form-label">Price</span>
                        <span className="price fs-5">
                          &nbsp; Rs:{productData.original_price}{" "}
                        </span>
                      </div>
                    ) : (
                      <div className="me-3">
                        <span className="form-label fs-4">Price</span>
                        <span className="price fs-5">
                          &nbsp; Rs:{productData.discount_price}{" "}
                          <del className="fs-6 fw-normal">
                            {productData.original_price}
                          </del>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <p
                  className="para-text"
                  dangerouslySetInnerHTML={{ __html: productData.description }}
                >
                  { }
                </p>
                <div className="meta-content d-flex align-items-end">
                  <div className="btn-quantity light me-0">
                    <label className="form-label">Select Size</label>
                    <div className="d-flex flex-wrap gap-2">
                      {productVarientsData.length < 1 ? (
                        <button
                          className="btn"
                          style={selectedStyle}
                          onClick={() =>
                            handleSizeChange({ target: { value: 0 } })
                          }
                        >
                          One Size
                        </button>
                      ) : (
                        productVarientsData.map((item, index) =>
                          item.variant_label_name === "Size" && item.value ? (
                            <button
                              key={index}
                              className="btn"
                              style={
                                selectedSize === item.variant_map_id
                                  ? selectedStyle
                                  : defaultStyle
                              }
                              onClick={() =>
                                handleSizeChange({
                                  target: { value: item.variant_map_id },
                                })
                              }
                            >
                              {item.value}
                            </button>
                          ) : null
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className=" cart-btn mt-3 d-flex justify-content-evenly">
                  <button
                    className="btn btn-md btn-outline-secondary btn-icon"
                    onClick={() => handleAddToCart(id)}
                  >
                    Add To Cart
                  </button>
                  <a
                    href="#"
                    className="btn btn-md btn-outline-secondary btn-icon"
                    onClick={() => handleWishList(id)}
                  >
                    <Heartsvgfile />
                    Add To Wishlist
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalProduct;
