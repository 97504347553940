import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from "react-router";

const Section6 = ({ sections }) => {
	const AppUrl = process.env.REACT_APP_IMAGE_URL;
	const title = sections?.find(
		(item) => item.key_name === "TITLE"
	)?.key_value;
	const title_2 = sections?.find(
		(item) => item.key_name === "TITLE_2"
	)?.key_value;
	const link = sections?.find((item) => item.key_name === "LINK")?.key_value;
	const desc = sections?.find((item) => item.key_name === "DESC")?.key_value;
	const button = sections?.find((item) => item.key_name === "BTN")?.key_value;
	const navigate = useNavigate();
	return (

		<section className="section_padding">
			<div className="banner cta-simple">
				<div className="banner-content">
					<div className="banner-header">
						<h3 className="banner-title text-uppercase font-weight-bold">
							{title}
						</h3>
					</div>
					<div className="banner-text">
						<h4>
							<span className="text-primary font-weight-bolder">
								{title_2}
							</span>
						</h4>
						<p>{desc}</p>
					</div>
					<a
						onClick={() => navigate(link)}
						className="btn btn-ellipse btn-md "
					>
						{button}
						<FontAwesomeIcon
							icon={faArrowRight}
							style={{ color: "#050505" }}
							className="ms-3"
						/>
					</a>
				</div>
			</div>
		</section>

	);
};
export default Section6;
