import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { getSubPageDetails } from '../../service/apiService';

const SubPages = () => {
  window.scrollTo({
    top: 0
  });
  const location = useLocation();
  const { id } = location.state || {};
  const [shopPageData, setShopPageData] = useState([]);
  const getFullLinkDataDetails = async () => {
    if (id) {
      const response = await getSubPageDetails(id);
      setShopPageData(response.data);
    }
  };
  useEffect(() => {
    getFullLinkDataDetails();
  }, [id]);
  return (
    <div>
      <h3 className="text-center">{shopPageData.page_name}</h3>
      <section className="section_poli_cont section_padding_py">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              dangerouslySetInnerHTML={{ __html: shopPageData.page_content }}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default SubPages