import React, { useState, useEffect } from "react";
import '../../../assert/css/campaign.css';
import { useNavigate } from "react-router-dom";
import image from '../../../assert/image/section_1.png';

const Champaign = ({ sections }) => {
    const AppUrl = process.env.REACT_APP_IMAGE_URL;
    
    
    const titleSection = sections?.find(section => section.key_name === "TITLE")?.key_value;
    const campaignSection = sections?.find(section => section.key_name === "CAMP");
    const campaign = campaignSection?.campaign_data?.[0];
    const expireDate = campaign ? campaign.expire_date : null;
    const banner = campaign ? campaign.image_path : null;
    const [countdown, setCountdown] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
    });
    const navigate = useNavigate();
    useEffect(() => {
        if (!expireDate) return;
        const [year, month, day] = expireDate.split("-");
        const countdownDate = new Date(`${year}-${month}-${day}T23:59:59`).getTime();
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;
            if (distance < 0) {
                clearInterval(interval);
                setCountdown({
                    days: "00",
                    hours: "00",
                    minutes: "00",
                    seconds: "00",
                });
            } else {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setCountdown({
                    days: String(days).padStart(2, "0"),
                    hours: String(hours).padStart(2, "0"),
                    minutes: String(minutes).padStart(2, "0"),
                    seconds: String(seconds).padStart(2, "0"),
                });
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [expireDate]);
    return (
        <div>
            <section className="sec_home_6 section_9 section_padding">
                <h1 className="section-title">{titleSection}</h1>
                <a onClick={() => navigate('/campaign-list')} style={{ cursor: 'pointer' }}>
                    <div className="container-fluid">
                        <div className="count_dow_par">
                            <img
                                src={image}
                                className="w-100 img-fluid d-sm-block"
                                alt=""
                            />
                            <div className="overlay-text d-sm-block">
                                <h1>{campaign?.title}</h1>
                                <h2>{campaign?.campaign_name}</h2>
                                <p>{campaign?.description}</p>
                            </div>
                            <img
                                src={image}
                                className="w-100 img-fluid d-sm-none"
                                alt=""
                            />
                            <div className="overlay-text d-sm-none">
                                <h1>{campaign?.title}</h1>
                                <h2>{campaign?.campaign_name}</h2>
                                <p>{campaign?.description}</p>
                            </div>
                            <div id="countdown">
                                <div className="time-box">
                                    <span id="days">{countdown.days}</span>
                                    <span>Days</span>
                                </div>
                                <div className="time-box">
                                    <span id="hours">{countdown.hours}</span>
                                    <span>Hours</span>
                                </div>
                                <div className="time-box">
                                    <span id="minutes">{countdown.minutes}</span>
                                    <span>Minutes</span>
                                </div>
                                <div className="time-box">
                                    <span id="seconds">{countdown.seconds}</span>
                                    <span>Seconds</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </section>
        </div>
    );
};
export default Champaign;







