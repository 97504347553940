import React, { useEffect, useState } from "react";
import "../../../assert/css/cartPage.css";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
// import emptyCart from "../../../assert/Image/emptycart.jpg";
import NewAdress from "./NewAdress";
import {
  applyCouponCode,
  deleteSingleCartItemAPICall,
  epmtyCartItemsAPICall,
  getAllCartProducts,
  getBillingAddressAPICall,
  getCartAmountListAPICall,
  getCartTotal,
  getShippingAddressAPICall,
  Makepayment,
  updateCartProductQuanityAPICall,
} from "../../../service/apiService";

const CheckoutPage = () => {
  // const [counter, setCounter] = useState(0)
  const [cartItems, setCartItems] = useState([]);
  const [totalCart, settotalCart] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setbillingAddress] = useState([]);
  const [defaultbillingAddress, setdefaultbillingAddress] = useState(null);
  const [defaultShippingAddress, setdefaultShippingAddress] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(false);
  const [billingAddressID, setBillingAddressID] = useState(null);
  const [shippingAddressID, setShippingAddressID] = useState(null);
  const [dataChange, setDatachange] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [validationErrors, setValidationErrors] = useState({ coupon: "" });
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const tokenCookie = localStorage.getItem("token");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchcartDetails(tokenCookie);
    fetchCartTotal(tokenCookie);
    getShippingAddress();
    getBillingAddress();
  }, []);

  const fetchcartDetails = async (token) => {
    try {
      const response = await getAllCartProducts(token);
      setCartItems(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCartTotal = async (token) => {
    try {
      const response = await getCartTotal(token);
      settotalCart(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const couponOnchange = (e, coupon) => {
    if (e.target.type === "text") {
      setCoupon(e.target.value);
    } else {
      if (e.target.checked) {
        setCoupon(coupon);
      } else {
        setCoupon("");
      }
    }
  };
  const ValidateCoupenCode = (coupenCode) => {
    if (!coupenCode) {
      return "Please provide a coupen code.";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const coupenError = ValidateCoupenCode(coupon);
      setValidationErrors({
        coupon: coupenError,
      });
      if (!coupenError) {
        const couponData = await applyCouponCode(tokenCookie, coupon);
        if (couponData.status_code === 200) {
          setIsCouponApplied(true);
          toast.success(couponData.message);
          setCoupon("");
          setDiscountPrice(true);
          getCartAmount();

          document.getElementById("coupon-code").value = "";
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.status_code === 400
      ) {
        setCoupon("");
        toast.warning(`${error.response.data.data.error}`);
        setError(error.response.data.data.error);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const quantityOnchangeHandle = async (type, data) => {
    let quantity;
    const tokenCookie = localStorage.getItem("token");
    if (type === "increase" && data.item_count < data.product_quantity) {
      quantity = data.item_count + 1;
    } else if (type === "decrease" && data.item_count > 1) {
      quantity = data.item_count - 1;
    } else {
      return;
    }
    let payload = {
      product_id: data.product_id,
      quantity: quantity,
    };
    try {
      const response = await updateCartProductQuanityAPICall(
        tokenCookie,
        payload
      );
      if (response.status_code == 200) {
        setDatachange(!dataChange);
        fetchcartDetails(tokenCookie);
        fetchCartTotal(tokenCookie);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCartAmount();
  }, [dataChange, isCouponApplied]);

  const getShippingAddress = async () => {
    try {
      const response = await getShippingAddressAPICall(tokenCookie);
      if (response.status_code == 200) {
        setShippingAddress(response.data);
        const defaultShippingAddress = response.data.find(
          (item) => item.shipping_set_default == 1
        );
        setdefaultShippingAddress(defaultShippingAddress);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getBillingAddress = async () => {
    try {
      const response = await getBillingAddressAPICall(tokenCookie);
      if (response.status_code == 200) {
        setbillingAddress(response.data);
        const defaultBillingAddress = response.data.find(
          (item) => item.billing_set_default == 1
        );
        setdefaultbillingAddress(defaultBillingAddress);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const placeOrderContinue = async () => {
    if (!billingAddressID || !shippingAddressID) {
      toast.warning("Please select billing and shipping address !");
      return;
    }
    const payload = {
      billing_address_id: billingAddressID,
      shipping_address_id: shippingAddressID,
    };
    try {
      const response = await Makepayment(payload, tokenCookie);
      if (response.status_code === 200) {
        toast.success("Order placed successfully !");
        const orderId = response.data;
        const data = { order_id: orderId };
        const encodedData = btoa(JSON.stringify(data));
        const laravelAppUrl = process.env.REACT_APP_LARAVEL_APP_URL;
        window.location.href = `${laravelAppUrl}/manual-payment?F1!sO=${encodedData}`;
        document.getElementById("placeOrderButton").disabled = false;
      }
    } catch (error) {
      document.getElementById("placeOrderButton").disabled = false;
      console.error(error);
    }
  };

  const handleDelete = async (data) => {
    const tokenCookie = localStorage.getItem("token");
    const payload = {
      product_id: data.product_id,
    };
    try {
      const response = await deleteSingleCartItemAPICall(payload, tokenCookie);
      if (response.status_code === 200) {
        toast.success("Item deleted successfully !");
        fetchcartDetails(tokenCookie);
        fetchCartTotal(tokenCookie);
      }
    } catch (error) {
      toast.error("Failed to delete item.");
      console.error(error);
    }
  };

  const [cartData, setCartData] = useState([]);
  const [cartAmount, setCartAmount] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState("");
  useEffect(() => {
    getCartItems();
    getCartAmount();
  }, []);

  const getCartItems = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await getAllCartProducts(token);
      if (response.status_code == 200) {
        setCartData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getCartAmount = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await getCartAmountListAPICall(token);
      if (response.status_code == 200) {
        setCartAmount(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSingleCartItem = (product) => {
    setDeleteItemId(product);
  };

  const confirmDeleteSingleCartItem = async (product_id) => {
    let payload = {
      product_id: product_id,
    };

    const confirm = window.confirm("Are you sure to delete this item?");
    if (confirm) {
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.product_id !== product_id)
      );

      try {
        const response = await deleteSingleCartItemAPICall(payload);
        if (response.status_code === 200) {
          toast.success("Cart item removed successfully!");
          fetchCartTotal(tokenCookie);
        } else {
          getCartItems();
          toast.error("Failed to delete item.");
        }
      } catch (error) {
        console.error(error);
        getCartItems();
        toast.error("Failed to delete item.");
      }
    }
  };

  const emptyCartItems = async () => {
    try {
      const response = await epmtyCartItemsAPICall();
      if (response.status_code == 200) {
        toast.success("All cart items removed successfully !");
        setCartAmount([]);
        setCartData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className="main cart">
      <div className="page-content pt-7 pb-10">
        <div className="step-by pr-4 pl-4">
          <h3 className="title title-simple title-step active">
            <div onClick={() => navigate("/checkout/cart")}>Shopping Cart</div>
          </h3>
        </div>
        {cartItems && cartItems?.length === 0 ? (
          <div className="container">
            <h3 className="text-center">No items in cart!</h3>
          </div>
        ) : (
          <div className="container mt-7 mb-2">
            <div className="row">
              <div className="col-lg-8 col-md-12 pr-lg-4">
                <table className="shop-table cart-table">
                  <thead>
                    <tr>
                      <th>
                        <span>Product</span>
                      </th>
                      <th></th>
                      <th>
                        <span>Price</span>
                      </th>
                      <th>
                        <span>Quantity</span>
                      </th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems &&
                      cartItems.map((data, index) => (
                        <tr key={index}>
                          <td className="product-thumbnail">
                            <figure>
                              <img
                                src={AppUrl + data.thumnail_image}
                                width="100"
                                height="100"
                                alt="product"
                              />
                            </figure>
                          </td>
                          <td className="product-name">
                            <div className="product-name-section">
                              {data.product_name}
                            </div>
                          </td>
                          <td className="product-subtotal">
                            <span className="amount">
                              Rs.{parseFloat(data.sales_price).toFixed(2)}
                            </span>
                          </td>
                          <td className="product-quantity ">
                            <div className="">
                              <button
                                type="button"
                                className="btn btn-light border rounded-circle btn-sm qtyDecreaseBtn"
                                onClick={() =>
                                  quantityOnchangeHandle("decrease", data)
                                }
                              >
                                -
                              </button>
                              <span className="qtyCount px-2">
                                {data.item_count}
                              </span>
                              <button
                                type="button"
                                className="btn btn-light border rounded-circle btn-sm qtyIncreaseBtn"
                                onClick={() =>
                                  quantityOnchangeHandle("increase", data)
                                }
                              >
                                +
                              </button>
                            </div>
                          </td>
                          <td className="product-price">
                            <span className="amount">
                              Rs.{data.sub_total.toFixed(2)}
                            </span>
                          </td>
                          <td
                            className="product-close"
                            onClick={() =>
                              confirmDeleteSingleCartItem(data.product_id)
                            }
                          >
                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {/* <div className="cart-actions mb-6 pt-4">
                <a
                  href="shop.html"
                  className="btn btn-dark btn-md btn-rounded btn-icon-left mr-4 mb-4"
                >
                  <i className="d-icon-arrow-left"></i>Continue Shopping
                </a>
                <button
                  type="submit"
                  className="btn btn-outline btn-dark btn-md btn-rounded btn-disabled"
                >
                  Update Cart
                </button>
              </div> */}

                <div className="">
                  <div className="cart_left">
                    <div className="default_other_addr col-md-12">
                      <div className="more_addr mb-3 ">
                        {shippingAddress?.length > 0 &&
                        billingAddress.length > 0 ? (
                          <>
                            <h6 className="add_cat_name fs-4 mb-4 fw-semibold">
                              Shipping and Billing Address
                            </h6>
                            <div className="d-flex gap-2 otherAddressContainer py-1 justify-content-evenly">
                              <div
                                style={{
                                  width: "290px",
                                  height: "290px",
                                  overflowY: "auto",
                                  scrollbarWidth: "thin",
                                }}
                              >
                                {shippingAddress &&
                                  shippingAddress?.length > 0 &&
                                  shippingAddress.map((address, index) => (
                                    <div
                                      key={index}
                                      className="sing_addr_par active"
                                    >
                                      <div className="sing_addr">
                                        <div
                                          className="d-flex justify-content-between
                                  "
                                        >
                                          <div className="d-flex justify-content-start">
                                            <input
                                              type="radio"
                                              checked={
                                                shippingAddressID ===
                                                address.shipping_address_id
                                              }
                                              onChange={() =>
                                                setShippingAddressID(
                                                  address.shipping_address_id
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="d-flex justify-content-end shipping-adress-design">
                                            Shipping
                                          </div>
                                        </div>
                                        <h6 className="fw-semibold fs-3 mb-1 mt-1">
                                          {address?.shipping_name}
                                        </h6>
                                        <p className="mb-2">
                                          {address?.shipping_address_line_one}
                                          {address?.shipping_address_line_two}
                                        </p>
                                        <p className="mb-2">
                                          {address?.shipping_country} ,
                                        </p>
                                        <p className="mb-2">
                                          {address?.shipping_state} ,
                                          {address?.shipping_city} ,
                                          {address?.billing_pincode}
                                        </p>
                                        <div className="phone">
                                          Mobile:{" "}
                                          <b>{address?.shipping_phone_no}</b>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div
                                style={{
                                  width: "290px",
                                  height: "290px",
                                  overflowY: "auto",
                                  scrollbarWidth: "thin",
                                }}
                              >
                                {billingAddress &&
                                  billingAddress?.length > 0 &&
                                  billingAddress.map((address, index) => (
                                    <div
                                      key={index}
                                      className="sing_addr_par active"
                                    >
                                      <div className="sing_addr">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex justify-content-start">
                                            <input
                                              type="radio"
                                              checked={
                                                billingAddressID ===
                                                address.billing_address_id
                                              }
                                              onChange={() =>
                                                setBillingAddressID(
                                                  address.billing_address_id
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="d-flex justify-content-end">
                                            <div className="shipping-adress-design">
                                              Billing
                                            </div>
                                          </div>
                                        </div>
                                        <h6 className="fw-semibold mt-1 fs-3 mb-1">
                                          {address?.billing_name}
                                        </h6>

                                        <p className="mb-2">
                                          {address?.billing_address_line_one}
                                          {address?.billing_address_line_two}
                                        </p>
                                        <p className="mb-2">
                                          {address?.billing_country} ,
                                        </p>
                                        <p className="mb-2">
                                          {address?.billing_state} ,
                                          {address?.billing_city} ,
                                          {address?.billing_pincode}
                                        </p>
                                        <div className="phone">
                                          Mobile:{" "}
                                          <b>{address?.billing_phone_no}</b>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          <Link
                            className="btn btn-primary add-new-btn d-flex justify-content-center align-items-center fs-5"
                            data-bs-toggle="modal"
                            data-bs-target="#addr_change"
                          >
                            ADD NEW ADDRESS
                          </Link>
                        )}
                      </div>
                      <hr style={{ color: "black" }} />

                      {shippingAddress?.length === 0 &&
                      billingAddress?.length === 0 ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "100px" }}
                        ></div>
                      ) : (
                        <Link
                          className="btn btn-primary add-new-btn d-flex justify-content-center align-items-center fs-5  "
                          data-bs-toggle="modal"
                          data-bs-target="#addr_change"
                        >
                          ADD NEW ADDRESS
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <aside className="col-lg-4 sticky-sidebar-wrapper">
                <div
                  className="sticky-sidebar"
                  data-sticky-options="{'bottom': 20}"
                >
                  <div className="summary mb-4">
                    <tr className="sumnary-shipping shipping-row-last">
                      <td colSpan="2">
                        <h4 className="summary-subtitle">Payment Method</h4>
                        <ul>
                          <li>
                            <div className="custom-radio">
                              <input
                                type="radio"
                                id="flat_rate"
                                name="shipping"
                                className="custom-control-input"
                                checked
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="flat_rate"
                              >
                                Online Payment
                              </label>
                            </div>
                          </li>
                          {/* <li>
                            <div className="custom-radio">
                              <input
                                type="radio"
                                id="free-shipping"
                                name="shipping"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="free-shipping"
                              >
                                Manual Payment
                              </label>
                            </div>
                          </li> */}
                        </ul>
                      </td>
                    </tr>
                    <div className="cart-coupon-box mb-8">
                      <h4 className="title coupon-title text-uppercase ls-m">
                        Coupon Discount
                      </h4>
                      {validationErrors.coupon && (
                        <Form.Text className="text-danger">
                          {validationErrors.coupon}
                        </Form.Text>
                      )}
                      <input
                        type="text"
                        name="coupon_code"
                        className="input-text form-control text-grey ls-m mb-4"
                        id="coupon_code"
                        placeholder="Enter coupon code here..."
                        value={coupon}
                        onChange={(e) => {
                          couponOnchange(e);
                        }}
                      />

                      <button
                        type="submit"
                        className="btn btn-md btn-dark btn-rounded btn-outline"
                        onClick={handleSubmit}
                      >
                        Apply Coupon
                      </button>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Price Details</h5>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Total Original Price</span>
                          <span>
                            ₹
                            {cartAmount.total_original_amount
                              ? cartAmount.total_original_amount.toFixed(2)
                              : ""}
                          </span>
                        </li>
                        {cartAmount.total_discount_amount > 0 && (
                          <li className="list-group-item d-flex justify-content-between">
                            <span>Your Saving Price</span>
                            <span className="text-success">
                              ₹
                              {cartAmount.total_discount_amount
                                ? cartAmount.total_discount_amount.toFixed(2)
                                : ""}
                            </span>
                          </li>
                        )}
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Total Tax</span>
                          <span>
                            ₹
                            {cartAmount.total_tax_amount
                              ? cartAmount.total_tax_amount.toFixed(2)
                              : ""}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Total Sales Amount</span>
                          <span>
                            ₹
                            {cartAmount.total_sales_amount
                              ? cartAmount.total_sales_amount.toFixed(2)
                              : ""}
                          </span>
                        </li>
                        {cartAmount.coupen_discount_price > 0 && (
                          <li className="list-group-item d-flex justify-content-between">
                            <span>Coupon Discount</span>
                            <span className="text-success">
                              ₹
                              {cartAmount.coupen_discount_price
                                ? cartAmount.coupen_discount_price.toFixed(2)
                                : ""}
                            </span>
                          </li>
                        )}

                        <li className="list-group-item d-flex justify-content-between">
                          <span>Shipping Fee</span>
                          <span className="text-success">FREE</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Total Amount</span>
                          <span>
                            ₹
                            {cartAmount.total_price
                              ? cartAmount.total_price.toFixed(2)
                              : ""}
                          </span>
                        </li>
                      </ul>
                      {cartItems?.length > 0 && (
                        <button
                          id="placeOrderButton"
                          className="theme_btn_1 btn btn-primary btn-block mt-3 fs-4"
                          onClick={()=>{
                            placeOrderContinue();
                          }}
                        >
                          Place Order
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <NewAdress
                  getShippingAddress={getShippingAddress}
                  getBillingAddress={getBillingAddress}
                />
              </aside>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default CheckoutPage;
