import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import {
  Navigation,
  Autoplay,
  Pagination,
  FreeMode,
  Thumbs,
  Zoom,
} from "swiper/modules";
import SwiperCore from "swiper";

import productimg from "../../assert/image/1.jpg";
SwiperCore.use([Navigation, Autoplay, Pagination, FreeMode, Thumbs, Zoom]);

const ModalSwiper = ({ productAlldata }) => {
  const bannerMediaList = [productimg, productimg, productimg];
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const imageStyle = {
    width: "100%",
    height: "100%",
    display: "block",
    margin: "0 auto",
  };
  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const thumbImageStyle = {
    width: "100%",
    height: "100%",
    display: "block",
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
            <Swiper
              onSwiper={setThumbsSwiper}
              slidesPerView={4}
              className="quick-modal-swiper thumb-swiper-lg thumb-sm swiper-vertical"
              spaceBetween={1}
              freeMode={true}
              watchSlidesProgress={true}
              direction="vertical"
              style={{
                width: "80%",
                height: "500px",
                overflowY: "auto",
                scrollbarWidth: "none",
                objectFit: "fit",
                border: "none",
              }}
            >
              {productAlldata.map((bannerMedia, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    padding: 0,
                    margin: 0,
                    height: "105px",
                    border:
                      selectedImageIndex === index
                        ? "2px solid #000000"
                        : "none",
                    cursor: "pointer",
                  }}
                  className="m-2"
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    src={AppUrl + bannerMedia.image_path}
                    alt={`thumbnail ${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="col-lg-10 col-md-5 col-sm-9 p-0  m-0 col-9">
          <Swiper
            loop={true}
            zoom={true}
            slidesPerView={1}
            modules={[Zoom, Navigation, Pagination]}
            spaceBetween={10}
            navigation={false}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            className="quick-modal-swiper2 my-auto swiper-horizontal "
          >
            {productAlldata.map((bannerMedia, index) => (
              <SwiperSlide
                key={index}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={AppUrl + bannerMedia.image_path}
                  style={{
                    maxWidth: "90%",
                    height: "auto",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                  alt={`product ${index}`}
                  // className="rounded-25"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ModalSwiper;
