import React, { useEffect, useState } from "react";
import {
  getBillingAddressAPICall,
  getShippingAddressAPICall,
} from "../../service/apiService";
import "../../assert/css/pagenation.css";

const AddressBook = () => {
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setbillingAddress] = useState([]);
  const tokenCookie = localStorage.getItem("token");

  const getShippingAddress = async () => {
    try {
      const response = await getShippingAddressAPICall(tokenCookie);
      if (response.status_code == 200) {
        setShippingAddress(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getBillingAddress = async () => {
    try {
      const response = await getBillingAddressAPICall(tokenCookie);
      if (response.status_code == 200) {
        setbillingAddress(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getShippingAddress();
    getBillingAddress();
  }, []);
  return (
    <div className="account-card container card mx-auto m-4">
      <div className="row">
        <div className="col-lg-6">
          <h5 className="d-flex justify-content-center">Shipping Address</h5>

          <div style={{ maxHeight: "400px", overflowY: "auto", scrollbarWidth:'none'}}>
            {shippingAddress.length > 0 ? (
              shippingAddress.map((address, index) => (
                <div
                  className="card m-5 w-75 ps-4"
                  key={index}
                >
                  <p className="mb-2">
                    <strong>Name:</strong> {address.shipping_name}
                  </p>
                  <p className="mb-2">
                    <strong>Email:</strong> {address.shipping_email}
                  </p>
                  <p className="mb-2">
                    <strong>Address:</strong>{" "}
                    {address.shipping_address_line_one},
                    {address.shipping_address_line_two}
                  </p>
                  <p className="mb-2">
                    <strong>City:</strong> {address.shipping_city}
                  </p>
                  <p className="mb-2">
                    <strong>State:</strong> {address.shipping_state}
                  </p>
                  <p className="mb-2">
                    <strong>Zip Code:</strong> {address.shipping_pincode}
                  </p>
                  <p className="mb-2">
                    <strong>Country:</strong> {address.shipping_country}
                  </p>
                  <p className="mb-2">
                    <strong>Phone Number:</strong> {address.shipping_phone_no}
                  </p>
                </div>
              ))
            ) : (
              <p>No Shipping addresses found.</p>
            )}
          </div>
        </div>

        <div className="col-lg-6">
          <h5 className="d-flex justify-content-center">Billing Address</h5>

          <div style={{ maxHeight: "400px", overflowY: "auto", scrollbarWidth:'none' }}>
            {billingAddress.length > 0 ? (
              billingAddress.map((address, index) => (
                <div className="card m-5 w-75 ps-4" key={index}>
                  <p className="mb-2">
                    <strong>Name:</strong> {address.billing_name}
                  </p>
                  <p className="mb-2">
                    <strong>Email:</strong> {address.billing_email}
                  </p>
                  <p className="mb-2">
                    <strong>Address:</strong> {address.billing_address_line_one}
                    ,{address.billing_address_line_two}
                  </p>
                  <p className="mb-2">
                    <strong>City:</strong> {address.billing_city}
                  </p>
                  <p className="mb-2">
                    <strong>State:</strong> {address.billing_state}
                  </p>
                  <p className="mb-2">
                    <strong>Zip Code:</strong> {address.billing_pincode}
                  </p>
                  <p className="mb-2">
                    <strong>Country:</strong> {address.billing_country}
                  </p>
                  <p className="mb-2">
                    <strong>Phone Number:</strong> {address.billing_phone_no}
                  </p>
                </div>
              ))
            ) : (
              <p>No billing addresses found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressBook;
