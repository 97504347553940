import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import noImage from "../../assert/image/1.jpg";
import { Hourglass } from "react-loader-spinner";
import "../../assert/css/main.css";
import '../../assert/css/home.css'
import '../../assert/css/custom.css'
import ProductFilter from "./ProductFilter";
import ProductSort from "./ProductSort";
import PaginationIndex from "./PagenationIndex";
import ModalProduct from "./ModalProduct";
import {
  addToWishList,
  getAllProductsAPICall,
  getBrandOptionsAPICall,
  getCategoriesOptionsAPICall,
  getFilterProductsAPICall,
  priceFilterRangeGetAPICall,
} from "../../service/apiService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faHeart } from "@fortawesome/free-solid-svg-icons";
const ProductMain = () => {
  window.scrollTo({
    top: 0
  });
  const [sortValue, setSortValue] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [brandOptions, SetBrandOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [priceRangeData, setPriceRangeData] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryUrlId, brandsUrlId, minPriceUrl,
    maxPriceUrl, searchData, searchTerm } =
    location.state || {};
  const productsPerPage = 20;


  const token = localStorage.getItem('token')

  useEffect(() => {
    getCategoriesOptions();
    getAllgetBrandOptions();
    priceFilterValueGet();
    if (categoryUrlId && brandsUrlId) {
      if (categoryUrlId == 0 && brandsUrlId != 0) {
        brandsOnchange(Number(brandsUrlId));
      } else if (categoryUrlId != 0 && brandsUrlId == 0) {
        categoriesOnchange(Number(categoryUrlId));
      } else if (categoryUrlId != 0 && brandsUrlId != 0) {
        categoryAndBrandsOnChange(Number(categoryUrlId), Number(brandsUrlId));
      }
      window.scrollTo(0, 0);
    } else if (searchData) {
      // alert()
      setProducts(searchData);
    } else {
      getAllProducts(sortValue);
    }
  }, [sortValue]);

  useEffect(() => {
    if (searchData) {
      setProducts(searchData);
      setNoMatchFound(false);
    } else {
      setProducts([]);
      setNoMatchFound(true);
    }
    if (searchTerm == "") {
      getAllProducts(sortValue);
    }
  }, [searchData, searchTerm]);

  const singlePageRedirection = (id) => {
    navigate(`/product_detail`, { state: { id } });
  };

  const getAllProducts = async () => {
    try {
      setIsLoading(true);
      const response = await getAllProductsAPICall(sortValue);
      setIsLoading(false);
      const status = await response.status_code;

      if (status === 200) {
        setProducts(response.data);
        setNoMatchFound(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(products.length / productsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const gotoFirstPage = () => {
    setCurrentPage(1);
  };

  const getCategoriesOptions = async () => {
    try {
      const response = await getCategoriesOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        setCategoriesOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllgetBrandOptions = async () => {
    try {
      const response = await getBrandOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        SetBrandOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const categoriesOnchange = async (categoryId) => {
    let categoryIDValue = selectedCategory === categoryId ? 0 : categoryId;
    let BrandIDValue = selectedBrand === null ? 0 : selectedBrand;
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
    // if (categoryIDValue !== 0 || BrandIDValue !== 0) {
    try {
      setIsLoading(true);
      const response = await getFilterProductsAPICall(
        categoryIDValue,
        BrandIDValue,
        minPriceUrl || minPrice,
        maxPriceUrl || maxPrice
      );
      setIsLoading(false);
      if (response) {
        setProducts(response.data || []);
        setNoMatchFound(false);
        setCurrentPage(1);
        if (response.data == null) {
          setNoMatchFound(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
    // } else {
    //   getAllProducts(sortValue);
    // }
  };

  const priceFilterOnchange = async (e) => {
    let categoryIDValue = selectedCategory === null ? 0 : selectedCategory;
    let BrandIDValue = selectedBrand === null ? 0 : selectedBrand;
    const { name, value } = e.target;
    if (name === "range-min") {
      if (parseInt(value) <= maxPrice) {
        setMinPrice(parseInt(value));
        try {
          const response = await getFilterProductsAPICall(
            categoryIDValue,
            BrandIDValue,
            value,
            maxPrice
          );
          if (response) {
            setProducts(response.data || []);
            setNoMatchFound(false);
            setCurrentPage(1);
            if (response.data == null) {
              setNoMatchFound(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else if (name === "range-max") {
      if (parseInt(value) >= minPrice) {
        setMaxPrice(parseInt(value));
        try {
          const response = await getFilterProductsAPICall(
            categoryIDValue,
            BrandIDValue,
            minPrice,
            value
          );
          if (response) {
            setProducts(response.data || []);
            setNoMatchFound(false);
            setCurrentPage(1);
            if (response.data == null) {
              setNoMatchFound(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const brandsOnchange = async (brandId) => {
    let categoryIDValue = selectedCategory === null ? 0 : selectedCategory;
    let BrandIDValue = selectedBrand === brandId ? 0 : brandId;
    if (selectedBrand === brandId) {
      setSelectedBrand(null);
    } else {
      setSelectedBrand(brandId);
    }

    // if (categoryIDValue !== 0 || BrandIDValue !== 0) {
    try {
      const response = await getFilterProductsAPICall(
        categoryIDValue,
        BrandIDValue,
        minPrice,
        maxPrice
      );
      if (response.status_code === 200) {
        setProducts(response.data || []);
        setNoMatchFound(false);
        setCurrentPage(1);
        if (response.data == null) {
          setNoMatchFound(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
    // } else {
    //   getAllProducts(sortValue);
    // }
  };
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const categoryAndBrandsOnChange = async (categoryIDValue, BrandIDValue) => {
    setSelectedCategory(categoryIDValue);
    setSelectedBrand(BrandIDValue);
    try {
      const response = await getFilterProductsAPICall(
        categoryIDValue,
        BrandIDValue
      );
      if (response) {
        setProducts(response.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowModal = (productId, product) => {
    setSelectedProduct(product);
    navigate("/shop", { state: { id: productId } });
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const priceFilterValueGet = async () => {
    try {
      const response = await priceFilterRangeGetAPICall();
      if (response.status_code === 200) {
        setPriceRangeData(response.data);
        setMinPrice(response.data.min_price_amount);
        setMaxPrice(response.data.max_price_amount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts =
    products !== null
      ? products.slice(indexOfFirstProduct, indexOfLastProduct)
      : null;

  const handleWishList = async (productId) => {
    const wishListResponse = await addToWishList(token, productId);
    if (wishListResponse.status === 401) {
      toast.error(wishListResponse.message);
    } else if (wishListResponse === 403) {
      toast.info("Product Already in wishlist");
    } else if (wishListResponse.status_code === 200) {
      toast.success(wishListResponse.message);
    }
  };

  return (
    <>
      <main>
        <section className="section_breadcrumb section_padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ul className="breadcrumb">
                  <li>
                    <Link style={{ textDecoration: "none" }} to="/">Home /</Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none" }} >Shop</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="section_p_f_fil_cont section_padding_py">
          <div className="container">
            <div className="row s_p_f_f_c_par">
              <ProductFilter
                categoriesOptions={categoriesOptions}
                brandOptions={brandOptions}
                categoriesOnchange={categoriesOnchange}
                brandsOnchange={brandsOnchange}
                selectedCategory={selectedCategory}
                selectedBrand={selectedBrand}
                priceRangeData={priceRangeData}
                priceFilterOnchange={priceFilterOnchange}
              />

              <div className="col-lg-9 s_p_f_f_c_right">
                <div>
                  <ProductSort
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                  />
                  <div className="row m-0 prdct_card_par">
                    {isLoading ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="loader"
                            style={{ width: "200px", height: "200px" }}
                          >
                            <Hourglass
                              color="#00BFFF"
                              height={200}
                              width={200}
                            />
                          </div>
                        </div>
                        <h2 style={{ textAlign: "center" }}>Loading......</h2>
                      </>
                    ) : (
                      currentProducts &&
                      currentProducts.length > 0 &&
                      currentProducts.map((product) => (
                        <div className="wrapper dy_product-w-p col-xl-3 col-md-4 col-sm-6" key={product.id}>
                          <div className="product">
                            <figure className="product-media">
                              <a
                                onClick={() =>
                                  singlePageRedirection(product.product_id)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={
                                    product.thumnail_image.endsWith(
                                      "undefined"
                                    ) || product.thumnail_image === ""
                                      ? noImage
                                      : AppUrl + product.thumnail_image
                                  }
                                  alt={product.product_name}
                                  width="280"
                                  height="315"
                                />
                              </a>
                              <div className="product-action-vertical">
                                <div
                                  key={product.product_id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    singlePageRedirection(product.product_id);
                                  }}
                                  className="btn-product-icon btn-wishlist"
                                  title="Add to cart"
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faCartShopping} style={{ color: "#ea2a3d", }} />
                                </div>
                                <a
                                  onClick={() => handleWishList(product.product_id)}
                                  class="btn-product-icon btn-wishlist"
                                  title="Add to wishlist"
                                >
                                  {/* <i className="bi bi-heart" style={{ color: 'black' }}></i> */}
                                  <FontAwesomeIcon icon={faHeart} style={{ color: "#e42f2f", }} />
                                </a>
                              </div>

                              <div className="product-action">
                                <a
                                  className="quickview-button "
                                  title="Quick View"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleShowModal(
                                      product.product_id,
                                      product
                                    );
                                  }}
                                  href="df"
                                  style={{ textDecoration: 'none' }}
                                >
                                  Quick View
                                </a>
                              </div>


                            </figure>
                            <div className="product-details">
                              <div className="product-cat">
                                <a style={{ textDecoration: "none", color: "grey" }}
                                  href="#"> {product.brand_name} </a>
                              </div>
                              <h3 className="product-name">
                                <a
                                  onClick={() =>
                                    singlePageRedirection(product.product_id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {product.product_name}
                                </a>
                              </h3>
                              <div className="product-price">
                                <ins className="new-price">
                                  Rs.{" "}
                                  {product.discount_percentage !== 0
                                    ? product.discount_price
                                    : product.original_price}
                                </ins>
                                {product.discount_percentage !== 0 && (
                                  <del className="old-price">
                                    Rs.{product.original_price}
                                  </del>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  {!noMatchFound && (
                    <PaginationIndex
                      totalProducts={products?.length}
                      productsPerPage={productsPerPage}
                      currentPage={currentPage}
                      gotoFirstPage={gotoFirstPage}
                      handleNextPage={handleNextPage}
                      handlePreviousPage={handlePreviousPage}
                    />
                  )}
                </div>
                {!isLoading && noMatchFound && (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    No match found...
                  </div>
                )}
              </div>
            </div>
            {selectedProduct && (
              <ModalProduct
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                selectedProduct={selectedProduct}
              />
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default ProductMain;
