import React, { useEffect, useState } from "react";

import { pageListData } from "../../../service/apiService";

import SideSection4 from "./SideNavBar/SideSection4";
import Section1 from "./Section1";
import Section4 from "./Section4";
import Section6 from "./Section6";
import Section8 from "./Section8";
import Section3 from "./Section3";
import Section7 from "./Section7";
import Section9 from "./Section9";
import Section10 from "./Section10";
import Section11 from "./Section11";
import Section12 from "./Section12";
import Section13 from "./Section13";
import Champaign from "./Champaign";
import Section14 from "./Section14";
import { useParams } from "react-router";

const Page = () => {
    const { slug } = useParams();
    const [sections, setSections] = useState({});
    const [data, setData] = useState([]);
    const pageIdMap = {
    about_us: 2,
    };
  
    const pageId = pageIdMap[slug] || 0;
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await pageListData(null, 2);
  
          if (!response.data) {
            console.error("API response is empty or null");
            setData([]); 
            setSections({}); 
            return;
          }
  
          setData(response.data);
  
          const groupedData = response.data.reduce((acc, section) => {
            const { section_name, section_group, place_order } = section;
  
            if (!acc[place_order]) {
              acc[place_order] = {};
            }
  
            if (!acc[place_order][section_name]) {
              acc[place_order][section_name] = {};
            }
  
            if (!acc[place_order][section_name][section_group]) {
              acc[place_order][section_name][section_group] = [];
            }
  
            acc[place_order][section_name][section_group].push(section);
            return acc;
          }, {});
  
          setSections(groupedData);
        } catch (error) {
  
          setData([]);
          setSections({});
        }
      };
  
      fetchData();
    }, [slug, pageId]);
  
    const renderComponent = (sections) => {
      if (!sections || Object.keys(sections).length === 0) {
        return <div>No data available</div>;
      }
  
      return Object.keys(sections).map((placeOrderKey) => {
        const placeOrder = sections[placeOrderKey];
        return Object.keys(placeOrder).map((sectionName) => {
          const sectionGroups = placeOrder[sectionName];
  
          return Object.keys(sectionGroups).map((sectionGroup) => {
          
          switch (sectionName) {
            case "section_1":
              return (
                <Section1
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_3":
              return (
                <Section3
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_2":
              return (
                <Section8
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );

            case "section_4":
              return (
                <Section4
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_5":
              return (
                <SideSection4
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_6":
              return (
                <Section6
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_7":
              return (
                <Section7
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_8":
              return (
                <Section9
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_9":
              return (
                <Section10
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_10":
              return (
                <Champaign
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_11":
              return (
                <Section11
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_12":
              return (
                <Section12
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_13":
              return (
                <Section13
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );case "section_14":
              return (
                <Section14
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );

            default:
              return null;
          }
        });
      });
    });
  };

  return (
    <div className="container section_padding_pb_pt-0">
      <div className="row">
        <div className="col-lg-12">{renderComponent(sections)}</div>
      </div>
    </div>
  );
};

export default Page;
