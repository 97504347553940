import React from "react";
import '../../../assert/css/section11.css';
import { useNavigate } from "react-router";
const Section11 = ({sections}) => {
    const navigate = useNavigate();
    const AppUrl = process.env.REACT_APP_IMAGE_URL;
    const ads = sections?.find((item) => item.key_name === "ADS")?.ads_data;
    const title = sections?.find((item) => item.key_name === "TITLE")?.key_value;
    return (
        <section className="about-section pb-10 ">
            <h1 className="section-title">{title}</h1>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <div className="image-overlay-container" onClick={() => navigate(ads[0]?.link_one)}>
                                    <img src={AppUrl + ads[0]?.image_path} alt="Business Year" className="w-100"/>
                                    <div className="image-overlay">
                                        <h5 className="font-weight-bold text-white ls-md">{ads[0]?.name}</h5>
                                        <p className="text-white mb-0">
                                        {ads[0]?.title_one}  
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="image-overlay-container" onClick={() => navigate(ads[1]?.link_one)}>
                                    <img src={AppUrl + ads[1]?.image_path} alt="Design Brands" className="w-100"/>
                                    <div className="image-overlay">
                                        <h5 className="font-weight-bold text-white ls-md">{ads[1]?.name}</h5>
                                        <p className="text-white mb-0">
                                        {ads[1]?.title_one}  
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="image-overlay-container" onClick={() => navigate(ads[2]?.link_one)}>
                                    <img src= {AppUrl + ads[2]?.image_path} alt="Team Members" className="w-100"/>
                                    <div className="image-overlay">
                                        <h5 className="font-weight-bold text-white ls-md">{ads[2]?.name}s</h5>
                                        <p className="text-white mb-0">
                                        {ads[2]?.title_one} 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section11;
