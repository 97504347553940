import React, { useEffect, useState } from "react";
import "../../../../assert/css/main.css";
import { useNavigate } from "react-router";
import { resentBlogs } from "../../../../service/apiService";

const SideSection4 = ({ sections }) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const title = sections?.find((item) => item.key_name === "TITLE")?.key_value;
  const Blogs = sections?.find((item) => item.key_name === "BLOG")?.key_value;
  const [recentBloges, setRecentBloges] = useState([]);
  const getRecentBlogesList = async () => {
    try {
      if (Blogs == 1) {
        const response = await resentBlogs();
        setRecentBloges(response.data);
      }
    } catch (error) { }
  };
  useEffect(() => {
    getRecentBlogesList();
  }, []);
  console.log(recentBloges, "recentBloges");
  const getBlogsSubCategoryData = async (id) => {
    navigate("/blogs-details", { state: { id } });
  };

  console.log(recentBloges, "recentBloges");
  return (
    <>
      {recentBloges?.length > 0 &&
        <section className="sec_home_blog widget widget-posts section_padding">
          <div className="title-wrapper title-cross mb-2">
          <h4 className="rect-title section-title mt-2 mb-3 mx-0 fs-1">{title}</h4>
          </div>
          <div className="widget-body">
            <div className="post-row row align-items-center">
              <div className="post post-list-lg col-lg-6">
                <div className="sing_blog">
                  <figure className="post-media" onClick={() => getBlogsSubCategoryData(recentBloges?.[0].blog_id)}>
                    <a >
                      <img
                        src={AppUrl + recentBloges?.[0]?.image_path}
                        alt="post"
                      />
                    </a>
                  </figure>
                  <div className="post-details">
                    <div className="post-meta">
                      <span className="post-date">
                        {recentBloges?.[0]?.create_date}
                      </span>
                    </div>
                    <h4 className="post-title">
                      <a >{recentBloges?.[0]?.title}</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="post-list-container col-lg-6">
                <div className="post post-list-sm">
                  <figure className="post-media" onClick={() => getBlogsSubCategoryData(recentBloges?.[1]?.blog_id)}>
                    <a >
                      <img
                        src={AppUrl + recentBloges?.[1]?.image_path}
                        width={84}
                        height={84}
                        alt="post"
                      />
                    </a>
                  </figure>
                  <div className="post-details">
                    <div className="post-meta">
                      <span className="post-date">
                        {recentBloges?.[1]?.create_date}
                      </span>
                    </div>
                    <h4 className="post-title">
                      <a >{recentBloges?.[1]?.title}</a>
                    </h4>
                  </div>
                </div>
                <div className="post post-list-sm">
                  <figure className="post-media" onClick={() => getBlogsSubCategoryData(recentBloges?.[2]?.blog_id)}>
                    <a >
                      <img
                        src={AppUrl + recentBloges?.[2]?.image_path}
                        width={84}
                        height={84}
                        alt="post"
                      />
                    </a>
                  </figure>
                  <div className="post-details">
                    <div className="post-meta">
                      <span className="post-date">
                        {recentBloges?.[2]?.create_date}
                      </span>
                    </div>
                    <h4 className="post-title">
                      <a >{recentBloges?.[2]?.title}</a>
                    </h4>
                  </div>
                </div>
                <div className="post post-list-sm">
                  <figure className="post-media" onClick={() => getBlogsSubCategoryData(recentBloges?.[3]?.blog_id)}>
                    <a >
                      <img
                        src={AppUrl + recentBloges?.[3]?.image_path}
                        width={84}
                        height={84}
                        alt="post"
                      />
                    </a>
                  </figure>
                  <div className="post-details">
                    <div className="post-meta">
                      <span className="post-date">
                        {recentBloges?.[3]?.create_date}
                      </span>
                    </div>
                    <h4 className="post-title">
                      <a >{recentBloges?.[3]?.title}</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default SideSection4;
