import {
    faBagShopping,
    faHeart,
    faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";

const Section10 = ({ sections }) => {
    const navigate = useNavigate();
    const title = sections?.find((item) => item.key_name === "TITLE")?.key_value;
    const classification = sections?.find(
        (item) => item.key_name === "CLASSIFI"
    )?.classification_data;
    const AppUrl = process.env.REACT_APP_IMAGE_URL;
    const singlePageRedirection = (id) => {
        navigate(`/product_detail`, { state: { id } });
    };
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
    };
    return (
        <section className="sec_home_3 section_padding">
            <div className="border_green py-5">
                <h2 className="section-title fs-1 rect-title">{title}</h2>
                <div className="col-lg-12 col-md-12 col-12 mt-4">
                    <Slider {...settings}>
                        {classification?.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    margin: "0 10px",
                                }}
                            >
                                <figure className="slide-image  m-3">
                                    <img
                                        src={AppUrl + item.thumnail_image}
                                        alt={item.product_name}
                                        className="banner-img"
                                        onClick={() =>
                                            singlePageRedirection(item.product_id)
                                        }
                                    />
                                </figure>

                                <div className="product-details pb-0">
                                    <h3 className="product-name">
                                        <a
                                            onClick={() =>
                                                singlePageRedirection(item.product_id)
                                            }
                                        >{item.product_name}</a>
                                    </h3>
                                    {item.discount_price > 0 ? (
                                        <>
                                            <span className="discount-price">
                                                &#8377;{item.discount_price}
                                            </span>
                                            <span
                                                className="original-price"
                                                style={{
                                                    textDecoration: "line-through",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                &#8377;{item.original_price}
                                            </span>
                                        </>
                                    ) : (
                                        <span className="price">&#8377;{item.original_price}</span>
                                    )}
                                    <div className="d-flex justify-content-between hover-container p-0">
                                        <a
                                            className="btn-product btn-cart d-flex"
                                            data-toggle="modal"
                                            data-target="#addCartModal"
                                            title="Add to cart"
                                        >
                                            <FontAwesomeIcon
                                                icon={faBagShopping}
                                                style={{ color: "#838891" }}
                                            />
                                            <span className="">Add to cart</span>
                                        </a>
                                        <a
                                            className="btn-product btn-cart d-flex"
                                            title="Add to wishlist"
                                        >
                                            <FontAwesomeIcon
                                                icon={faHeart}
                                                style={{ color: "#030303" }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default Section10;
