import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image from "../../../assert/image/1.jpg";
import "../../../assert/css/main.css";
import { useNavigate } from "react-router";

const settings = {
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 3000,
	arrows: false,

};

const Section1 = ({ sections }) => {
	const navigate = useNavigate();
	const img1 = sections?.find((item) => item.key_name === "IMG_1");
	const img2 = sections?.find((item) => item.key_name === "IMG_2");
	const img3 = sections?.find((item) => item.key_name === "IMG_3");

	const title1 = sections?.find(
		(item) => item.key_name === "TITLE_1"
	)?.key_value;
	const title2 = sections?.find(
		(item) => item.key_name === "TITLE_2"
	)?.key_value;
	const title3 = sections?.find(
		(item) => item.key_name === "TITLE_3"
	)?.key_value;

	const desc1 = sections?.find((item) => item.key_name === "DESC_1")?.key_value;
	const desc2 = sections?.find((item) => item.key_name === "DESC_2")?.key_value;
	const desc3 = sections?.find((item) => item.key_name === "DESC_3")?.key_value;

	const link1 = sections?.find((item) => item.key_name === "LINK_1")?.key_value;
	const link2 = sections?.find((item) => item.key_name === "LINK_2")?.key_value;
	const link3 = sections?.find((item) => item.key_name === "LINK_3")?.key_value;
	const ads = sections?.find((item) => item.key_name === "ADS")?.ads_data;
	const imgData = [
		{ img: img1, title: title1, desc: desc1, link: link1 },
		{ img: img2, title: title2, desc: desc2, link: link2 },
		{ img: img3, title: title3, desc: desc3, link: link3 },
	];

	const AppUrl = process.env.REACT_APP_IMAGE_URL;
	const AddsToProductsPage = (url) => {
		if (url.includes('get-filter-products')) {
		  const parts = url.split('/');
		  const categoryUrlId = parts[parts.indexOf('get-filter-products') + 1];
		  const brandsUrlId = parts[parts.indexOf('get-filter-products') + 2];
		  const minPriceUrl = parts[parts.indexOf('get-filter-products') + 3];
		  const maxPriceUrl = parts[parts.indexOf('get-filter-products') + 4];
		  navigate('/shop', { state: { categoryUrlId, brandsUrlId, minPriceUrl, maxPriceUrl } });
		}
	  };
	return (
		<div className="sec_home_9 row section_padding">

			<div
				className="col-xl-8 col-lg-8 col-sm-12"
			>
				<Slider {...settings}>
					{ads?.map((item, index) => (
						<div
							className="intro-slide1 banner banner-fixed"
							style={{ backgroundColor: "#eee", position: "relative" }}
							key={index}
						>
							<figure style={{ margin: 0 }}>
								<img
									src={AppUrl + item.image_path}
									width="580"
									height="500"
									alt="banner"
								/>
							</figure>
							<div
								className="banner-content"
								style={{
									position: "absolute",
									top: "auto",
									zIndex: 10,
									color: "#000",
								}}
							>
								<div className="slide-animate">
									{/* <h4 className="banner-subtitle font-weight-semi-bold mb-2">
										{item.name}
									</h4>
									<h3 className="banner-title mb-2">{item.title_one}</h3> */}
									<a
										onClick={() => AddsToProductsPage(item.link_one)}
										className="btn banner-btn btn-md btn-outline btn-dark mb-3"
									>
										Shop now
									</a>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>

			<div
				className="col-xl-4 col-lg-4"
			>
				<div className="right_side">
					{imgData?.map((item, index) => (


						<div className="d-flex flex-column p-0">
							<div className="col-lg-12 " style={{ borderRadius: "20px" }}>
								<div
									className="category category-banner category-absolute"
									data-animation-options="{ 'name': 'fadeInLeftShorter', 'delay': '.1s' }"
									style={{ backgroundColor: "#edf1f2", borderRadius: "5px" }}
								>
									<a>
										<figure className="category-media">
											<img
												src={AppUrl + item?.img?.key_value}
												alt="banner"
											/>
										</figure>
									</a>
									<div className="category-content d-block text-left">
										{/* <h4 className="category-name">{item.title}</h4>
										<p className="category-count">
											<span>{item.desc}</span>
										</p> */}
										<a
											onClick={() => AddsToProductsPage(item.link)}
											className="btn btn-light  btn-md    mb-3" style={{ position: 'unset' }}
										>
											Shop Now
										</a>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>

			</div>
		</div>

	);
};

export default Section1;