import React, { useState } from "react";
import { useSelector } from "react-redux";
import { contactUsSubmit } from "../../service/apiService";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    message: "",
    name: "",
    email: "",
    phone_no: "",
  });
  const [errors, setErrors] = useState({});
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector((state) => state.settings.settingsData);

  const addressLinkOne = settingsData.find(
    (item) => item.key_name === "ADDRESS_ONE"
  )?.key_value;
  const phoneNumberone = settingsData.find(
    (item) => item.key_name === "PHONE_ONE"
  )?.key_value;
  const phoneNumbertwo = settingsData.find(
    (item) => item.key_name === "PHONE_TWO"
  )?.key_value;
  const emailOne = settingsData.find(
    (item) => item.key_name === "EMAIL_ONE"
  )?.key_value;
  const emailTwo = settingsData.find(
    (item) => item.key_name === "EMAIL_TWO"
  )?.key_value;
  const locationMapLink = settingsData?.find(
    (item) => item.key_name === "LOCATE_MAP_LINK"
  )?.key_value;
  const contactBackgroundImage = settingsData?.find(
    (item) => item.key_name === "CONTACT_US_BG_IMG"
  )?.key_value;
  console.log(settingsData, "settingsdata");

  const validate = () => {
    let tempErrors = {};

    if (!formData.message) tempErrors.message = "Comment is required.";
    if (!formData.name) tempErrors.name = "Name is required.";
    if (!formData.email) {
      tempErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email address is invalid.";
    }
    if (!formData.phone_no) {
      tempErrors.phone_no = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone_no)) {
      tempErrors.phone_no = "Phone number is invalid.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      // If form is valid, proceed to submit data
      const newformData = {
        ...formData,
        phone_no: parseInt(formData.phone_no),
      };
      contactUsSubmit(newformData);

      // Reset form fields
      setFormData({ message: "", name: "", email: "", phone_no: "" });
    }
  };

  return (
    <main className="main">
      <nav className="breadcrumb-nav">
        <div className="container">
          <ul className="breadcrumb">
            <li>
              <a href="demo1.html">
                <i className="d-icon-home" />
              </a>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </nav>

      <div
        className="page-header"
        style={{ backgroundImage: `url(${AppUrl}${contactBackgroundImage})` }}
      >
        <h1 className="page-title font-weight-bold text-capitalize">
          Contact Us
        </h1>
      </div>

      <div className="page-content mt-10 pt-7">
        <section className="contact-section">
          <div className="container">
            <div className="row">

                <div className="contact-info grey-section d-flex align-items-center p-4 rounded shadow-lg">
                  <div>
                    <h4 className="mb-3 text-capitalize font-weight-semibold">
                      Address
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: addressLinkOne }}
                    ></div>
                    <h4 className="mt-4 mb-3 text-capitalize font-weight-semibold">
                      Phone Number
                    </h4>
                    <p>
                      <a href="tel:#" className="text-primary">
                        {phoneNumberone}
                      </a>
                      <br />
                      <a href="tel:#" className="text-primary">
                        {phoneNumbertwo}
                      </a>
                    </p>
                    <h4 className="mt-4 mb-3 text-capitalize font-weight-semibold">
                      Support
                    </h4>
                    <p>
                      <a href="#" className="text-primary">
                        {emailOne}
                      </a>
                      <br />
                      <a href="#" className="text-primary">
                        {emailTwo}
                      </a>
                    </p>
                  </div>
                </div>
              </div>

                <div className="contact-form rounded shadow-lg p-4">
                  <form onSubmit={handleSubmit}>
                    <h4 className="font-weight-bold mb-4">Let’s Connect</h4>
                    <p className="text-muted">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <div className="row">
                      <div className="col-12 col-md-6 mb-4">
                        <textarea
                          className="form-control form-control-lg py-5 fs-4"
                          required
                          placeholder="Comment*"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                        {errors.message && (
                          <p className="text-danger">{errors.message}</p>
                        )}
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <input
                          className="form-control form-control-lg py-4 fs-4"
                          type="text"
                          placeholder="Phone No *"
                          required
                          name="phone_no"
                          value={formData.phone_no}
                          onChange={handleChange}
                        />
                        {errors.phone_no && (
                          <p className="text-danger">{errors.phone_no}</p>
                        )}
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <input
                          className="form-control form-control-lg py-4 fs-4"
                          type="text"
                          placeholder="Name *"
                          required
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <p className="text-danger">{errors.name}</p>
                        )}
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <input
                          className="form-control form-control-lg py-4 fs-4"
                          type="email"
                          placeholder="Email *"
                          required
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <p className="text-danger">{errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-primary btn-md mx-auto  rounded">
                        Post Comment
                        <i className="ml-2" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>

        </section>

        <div
          className="grey-section google-map rounded shadow-lg mt-5"
          style={{ height: 386 }}
        >
          <iframe
            src={locationMapLink}
            style={{
              border: 0,
              width: "100%",
              height: "100%",
              borderRadius: "0 0 8px 8px",
            }}
            allowFullScreen
          />
        </div>
      </div>
    </main>
  );
};

export default ContactUs;
