import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OrderImage from "../../assert/image/parcel-box-package-icon.png";
import { downloadInvoice } from "./downloadInvoice";
import { useSelector } from "react-redux";
import {
  cancelOrder,
  getmyOrderListDetails,
  orderReturnRequest,
} from "../../service/apiService";

const OrderDetails = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector((state) => state.settings.settingsData);
  const loginLogo = settingsData.find(
    (setting) => setting.key_name === "SITE_LOGO"
  );
  let loginPageImg = "";
  if (loginLogo && loginLogo.key_value) {
    loginPageImg = AppUrl + loginLogo.key_value;
  }
  const address = settingsData.find(
    (setting) => setting.key_name === "ADDRESS_ONE"
  );

  const location = useLocation();
  const { id } = location.state || {};
  const [orderReturnState, setOrderReturnState] = useState(true);
  const [cancelOrderState, setCancelOrderState] = useState(true);
  const token = localStorage.getItem("token");
  const [productDetails, setProductDetails] = useState([]);
  const vieworderDetailPage = async () => {
    try {
      const response = await getmyOrderListDetails(id, token);
      setProductDetails(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    vieworderDetailPage();
  }, []);
  const handleReturnRequest = (order_code) => {
    orderReturnRequest(order_code, token);
    setOrderReturnState(false);
  };

  const handleCancelOrder = (order_code) => {
    cancelOrder(order_code, token);
    setCancelOrderState(false);
  };
  const getStatusClass = (status) => {
    switch (status) {
      case "unpaid":
        return "badge bg-warning d-flex flex-column";
      case "refund":
        return "badge bg-primary d-flex flex-column";
      case "paid":
        return "badge bg-success title";
      default:
        return "badge bg-secondary title";
    }
  };
  const handleDownload = async (orderId) => {
    await downloadInvoice(orderId, loginPageImg);
  };
  return (
    <section className="col-xl-9">
      <div className="card mb-4">
        <div className="card-header d-flex align-items-center">
          <img
            src={OrderImage}
            alt=""
            className="me-3"
            style={{ width: "50px", height: "50px" }}
          />
          <div className="d-flex flex-column">
            <span className="badge bg-secondary">
              {productDetails.order_status}
            </span>
            <h4 className="mb-0">{productDetails.order_code}</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-sm-6">
              <div className="mb-2">
                <span className="fw-semibold fs-4">Name:</span>
                <h6  className="fw-semibold">{productDetails.customer_name}</h6>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <span className="fw-semibold fs-4">Email:</span>
                <h6  className="fw-semibold">{productDetails.customer_email}</h6>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <span className="fw-semibold fs-4">Payment Status:</span>
                <h6 className={getStatusClass(productDetails.payment_status)}>
                  {productDetails.payment_status}
                </h6>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <span className="fw-semibold fs-4">Address:</span>
                <h6  className="fw-semibold">
                  {productDetails.shipping_address?.shipping_address_line_one}
                </h6>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <span className="fw-semibold fs-4">Total Products:</span>
                <h6  className="fw-semibold">{productDetails.total_products}</h6>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <span className="fw-semibold fs-4">Total Item:</span>
                <h6 className="fw-semibold">{productDetails.total_count}</h6>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <button
              className="btn btn-outline-primary"
              style={{ width: "150px" }}
              onClick={() => handleDownload(productDetails.order_id)}
            >
              <strong>Invoice</strong>
            </button>
            {productDetails.order_status === "delivered" &&
            productDetails.payment_status === "paid" &&
            orderReturnState ? (
              <button
                className="btn btn-outline-primary"
                onClick={() => handleReturnRequest(productDetails.order_code)}
              >
                <strong>RETURN REQUEST</strong>
              </button>
            ) : (
              (productDetails.order_status === "pending" ||
                productDetails.order_status === "processing" ||
                productDetails.order_status === "shipping") &&
              productDetails.order_status !== "cancelled" &&
              cancelOrderState && (
                <button
                  className="btn btn-outline-danger"
                  onClick={() => handleCancelOrder(productDetails.order_code)}
                >
                  <strong>ORDER CANCEL</strong>
                </button>
              )
            )}
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <ul className="nav nav-tabs mb-4" id="nav-tab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="nav-Item-tab"
                data-bs-toggle="tab"
                href="#nav-Item"
                role="tab"
                aria-controls="nav-Item"
                aria-selected="true"
              >
                Item Details
              </a>
            </li>
          </ul>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-Item"
              role="tabpanel"
              aria-labelledby="nav-Item-tab"
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Discount Amount</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {productDetails.purchase_list?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.product_name}</td>
                      <td>&#8377;{item.price}</td>
                      <td>{item.quantity}</td>
                      <td>&#8377;{item.discount_price}</td>
                      <td className="text-success">
                        &#8377;{item.sales_amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <span>TOTAL AMOUNT</span>
            <span>&#8377;{productDetails.total_amount}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
