import React, { useState } from "react";
import "../../../assert/css/main.css";
import { useNavigate } from "react-router";
import { emailSubscribe } from "../../../service/apiService";
import { toast } from "react-toastify";

const Section3 = ({ sections }) => {


    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const AppUrl = process.env.REACT_APP_IMAGE_URL;
    const title = sections.find(
        (section) => section.key_name === "NL_TITLE"
    )?.key_value;
    const discription = sections.find(
        (section) => section.key_name === "NL_DESC"
    )?.key_value;
    const backgroundImage = sections.find(
        (section) => section.key_name === "File"
    )?.key_value;

    const adsData =
        sections.find((section) => section.key_name === "ADS")?.ads_data || [];
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setError('Email is required');
            return;
        }
        setError('');

        try {
            const response = await emailSubscribe(email);


            if (response.status_code === 200) {
                setEmail('');
                // toast.success('Subscribed successfully');
                document.getElementById('email').value = '';
            } else {
                // toast.error('Failed to subscribe');
                setEmail('');
                document.getElementById('email').value = '';
            }
        } catch (error) {
            //   toast.error('An error occurred. Please try again later.');
        }
    };
    const AddsToProductsPage = (url) => {
		if (url.includes('get-filter-products')) {
		  const parts = url.split('/');
		  const categoryUrlId = parts[parts.indexOf('get-filter-products') + 1];
		  const brandsUrlId = parts[parts.indexOf('get-filter-products') + 2];
		  const minPriceUrl = parts[parts.indexOf('get-filter-products') + 3];
		  const maxPriceUrl = parts[parts.indexOf('get-filter-products') + 4];
		  navigate('/shop', { state: { categoryUrlId, brandsUrlId, minPriceUrl, maxPriceUrl } });
		}
	  };
    return (
        <div className="sec_home_new_lett row d-flex section_padding">
            <div className="col-12 col-lg-4 col-md-12 h-md-250 mb-4 mb-lg-0 d-flex flex-column justify-content-center"
                style={{
                    backgroundImage: `url(${AppUrl + backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}>
                <div
                    className="sticky-sidebar pb-8 mt-2"
                    data-sticky-options="{'top': 67}"
                >
                    <div className="banner banner-newsletter">
                        <div className="banner-content text-center">
                            <p className="banner-subtitle">{title}</p>
                            <h3 className="banner-title">{discription}</h3>
                            <form
                                onSubmit={handleSubmit}
                            >
                                <input
                                    type="email"
                                    className="form-control font-primary form-solid font-italic"
                                    name="email"
                                    id="email"
                                    placeholder="Enter Your E-mail Address..."
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <button className="btn btn-secondary" type="submit">
                                    Subscribe
                                </button>
                                <br></br>
                                {error && <p className="text-danger mt-2 text-center font-size-10 font-weight-bold ">{error}</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 h-md-250 mb-4 mb-md-0">
                <div
                    className="banner banner-fixed banner-sale mb-4"
                    style={{ backgroundColor: "#333359" }}
                >
                    <figure>
                        <img
                            src={AppUrl + adsData[0]?.image_path}
                            width={280}
                            height={312}
                            alt="banner"
                        />
                    </figure>
                    <div className="banner-content">
                        <h4 className="banner-subtitle mb-2 text-uppercase text-dark">
                            {adsData[0]?.name}
                        </h4>
                        <h3 className="banner-title text-dark mb-4">
                            {adsData[0]?.title_one}
                        </h3>
                        <a
                            onClick={() => AddsToProductsPage(adsData[0]?.link_one)}
                            className="shop-now-trans "
                        >
                            Shop Now
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 h-md-250">
                <div
                    className="banner banner-fixed banner-sale mb-4"
                    style={{ backgroundColor: "#333359" }}
                >
                    <figure>
                        <img
                            src={AppUrl + adsData[1]?.image_path}
                            width={280}
                            height={312}
                            alt="banner"
                        />
                    </figure>
                    <div className="banner-content">
                        <h4 className="banner-subtitle mb-2 text-uppercase text-dark ">
                            {adsData[1]?.name}
                        </h4>
                        <h3 className="banner-title text-dark mb-4 text-dark">
                            {" "}
                            {adsData[1]?.title_one}
                        </h3>
                        <a
                            onClick={() => AddsToProductsPage(adsData[1]?.link_one)}
                            className="shop-now-trans"
                        >
                            Shop Now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section3;
