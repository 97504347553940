import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { checkOtp } from "../../service/apiService";




export const validateOtp = (otp) => {
    if (!otp) {
      return "Please provide a otp.";
    }
    if (/[^0-9]+/g.test(otp)) {
      return "Please provide a valid otp ,otp contain only number .";
    }
    if (otp.length !== 6) {
      return "Please provide a valid otp of 6 digits.";
    }
    return "";
  };
const OtpPage = () => {
  const navigate = useNavigate();
const email = localStorage.getItem('email')
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const handleChange = (index, value) => {
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < otp.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleSubmit = async () => {
    const otpString = otp.join("");
    const otpError = validateOtp(otpString);

    setValidationError(otpError);

    if (!otpError) {
      try {
        const payload =
        {
          email: email,
          otp_code: otpString,
        };

        const response = await checkOtp(payload);
        if (response.status_code === 200) {
          toast.success("Otp verified successfully, Please login");
          navigate("/login");
        } else {
          toast.error(response.data.message || "Invalid OTP");
          setOtp(["", "", "", "", "", ""]);
        }
      } catch (error) {
        toast.error("Error verifying OTP. Please Provide valid OTP.");
        setOtp(["", "", "", "", "", ""]);
      }
    } else {
      toast.error(otpError);
      setOtp(["", "", "", "", "", ""]);
    }
  };

  return (
    <div className="otp-container">
    <h1 className="otp-header text-center" style={{ marginBottom: "50px", color: "#333", fontWeight: "bold", fontSize: "32px" }}>
      Enter OTP
    </h1>
    <div className="otp-page">
      <div className="otp-form">
        <div className="otp-input-container" style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
          {otp.map((value, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="text"
              className="otp-input"
              maxLength="1"
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              style={{
                borderBottom: "3px solid #007bff",
                marginRight: "10px",
                fontSize: "20px",
                width: "60px",
                textAlign: "center",
                outline: "none",
                transition: "border-color 0.3s ease",
              }}
              onFocus={(e) => e.target.style.borderColor = "#28a745"}
              onBlur={(e) => e.target.style.borderColor = "#007bff"}
            />
          ))}
        </div>
      </div>
    </div>
    {validationError && (
      <Form.Text className="text-danger fs-4" style={{ textAlign: "center", display: "block", marginBottom: "20px" }}>
        {validationError}
      </Form.Text>
    )}
    <div className="otp-submit-container text-center">
      <button
        className="otp-submit-btn btn btn-success"
        onClick={handleSubmit}
        style={{
          padding: "10px 20px",
          fontSize: "18px",
          borderRadius: "5px",
          backgroundColor: "#28a745",
          borderColor: "#28a745",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s ease",
        }}
        onMouseEnter={(e) => e.target.style.backgroundColor = "#218838"}
        onMouseLeave={(e) => e.target.style.backgroundColor = "#28a745"}
      >
        Submit
      </button>
    </div>
  </div>
  
  );
};

export default OtpPage;
