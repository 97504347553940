import React from 'react'
import { useNavigate } from 'react-router'

const Section14 = ({ sections }) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL
  const title = sections?.find(item => item.key_name === "TITLE")?.key_value
  const image = sections?.find(item => item.key_name === "ADS")?.ads_data
  const navigate = useNavigate()
  const AddsToProductsPage = (url) => {
    if (url.includes('get-filter-products')) {
      const parts = url.split('/');
      const categoryUrlId = parts[parts.indexOf('get-filter-products') + 1];
      const brandsUrlId = parts[parts.indexOf('get-filter-products') + 2];
      const minPriceUrl = parts[parts.indexOf('get-filter-products') + 3];
      const maxPriceUrl = parts[parts.indexOf('get-filter-products') + 4];
      navigate('/shop', { state: { categoryUrlId, brandsUrlId, minPriceUrl, maxPriceUrl } });
    }
  };
  return (
    <div className='container mt-3 pt-5'>
      <div className="title-wrapper title-cross">
        <h2 className="title">{title}</h2>
      </div>
      <img src={AppUrl + image[0]?.image_path} alt="" onClick={() => AddsToProductsPage(image[0]?.link_one)} />
    </div>
  )
}

export default Section14;
