import React, { useEffect, useState } from "react";
// import ProductFilter from "./ProductFilter";
// import ProductSort from "./ProductSort";
// import productimg from "../../../assets/img/product_images/single_prod.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faHeart,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { Hourglass } from "react-loader-spinner";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import noImage from "../../assert/image/Nologo.svg.png";
// import PaginationIndex from "../../../components/Paginations/PaginationIndex";
import {
  addToWishList,
  getAllProductsAPICall,
  getBrandOptionsAPICall,
  getCategoriesOptionsAPICall,
  getCategoryProductsAPICall,
  getFilterProductsAPICall,
  priceFilterRangeGetAPICall,
} from "../../service/apiService";
import PaginationIndex from "../../pages/Shop/PagenationIndex";
import ModalProduct from "../../pages/Shop/ModalProduct";
import ProductSort from "../../pages/Shop/ProductSort";
import ProductFilter from "../../pages/Shop/ProductFilter";
import { toast } from "react-toastify";

const CategoryPage = () => {
  const urlParams = useParams();
  const [sortValue, setSortValue] = useState("");
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [brandOptions, SetBrandOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [priceRangeData, setPriceRangeData] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const navigate = useNavigate();
  const productsPerPage = 20;
  const location = useLocation();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  const { productID } = location.state || {};
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  // useEffect(() => {
  //     getAllProducts();
  // }, [])
  useEffect(() => {
    getAllProducts();
    getCategoriesOptions();
    getAllgetBrandOptions();
    priceFilterValueGet();
  }, [urlParams.categoryName]);

  const singlePageRedirection = (id) => {
    navigate(`/product_detail`, { state: { id } });
  };

  const getAllProducts = async () => {
    try {
      setIsLoading(true);
      const response = await getCategoryProductsAPICall(productID);
      setIsLoading(false);
      const status = await response.status_code;
      if (status === 200) {
        setProducts(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(products.length / productsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const gotoFirstPage = () => {
    setCurrentPage(1);
  };

  const getCategoriesOptions = async () => {
    try {
      const response = await getCategoriesOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        setCategoriesOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllgetBrandOptions = async () => {
    try {
      const response = await getBrandOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        SetBrandOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllProductOrder(sortValue);
  }, [sortValue]);
  const getAllProductOrder = async () => {
    try {
      const response = await getAllProductsAPICall(sortValue);
      const status = await response.status_code;

      if (status === 200) {
        setProducts(response.data);
        setNoMatchFound(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const categoriesOnchange = async (categoryId) => {
    let categoryIDValue = selectedCategory === categoryId ? 0 : categoryId;
    let BrandIDValue = selectedBrand === null ? 0 : selectedBrand;
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
    if (categoryIDValue !== 0 || BrandIDValue !== 0) {
      try {
        const response = await getFilterProductsAPICall(categoryIDValue, BrandIDValue, minPrice, maxPrice);
        if (response) {
          setProducts(response.data || []);
          setNoMatchFound(false);
          if (response.data == null) {
            setNoMatchFound(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      getAllProducts();
    }
  };

  const brandsOnchange = async (brandId) => {
    let categoryIDValue = selectedCategory === null ? 0 : selectedCategory;
    let BrandIDValue = selectedBrand === brandId ? 0 : brandId;
    if (selectedBrand === brandId) {
      setSelectedBrand(null);
    } else {
      setSelectedBrand(brandId);
    }

    if (categoryIDValue !== 0 || BrandIDValue !== 0) {
      try {
        const response = await getFilterProductsAPICall(categoryIDValue, BrandIDValue, minPrice, maxPrice);
        if (response) {
          setProducts(response.data || []);
          setNoMatchFound(false);
          if (response.data == null) {
            setNoMatchFound(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      getAllProducts();
    }
  };

  const priceFilterOnchange = async (e) => {
    let categoryIDValue = selectedCategory === null ? 0 : selectedCategory;
    let BrandIDValue = selectedBrand === null ? 0 : selectedBrand;
    const { name, value } = e.target;
    if (name === "range-min") {
      if (parseInt(value) <= maxPrice) {
        setMinPrice(parseInt(value));
        try {
          const response = await getFilterProductsAPICall(
            categoryIDValue,
            BrandIDValue,
            value,
            maxPrice
          );
          if (response) {
            setProducts(response.data || []);
            setNoMatchFound(false);
            setCurrentPage(1);
            if (response.data == null) {
              setNoMatchFound(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else if (name === "range-max") {
      if (parseInt(value) >= minPrice) {
        setMaxPrice(parseInt(value));
        try {
          const response = await getFilterProductsAPICall(
            categoryIDValue,
            BrandIDValue,
            minPrice,
            value
          );
          if (response) {
            setProducts(response.data || []);
            setNoMatchFound(false);
            setCurrentPage(1);
            if (response.data == null) {
              setNoMatchFound(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const priceFilterValueGet = async () => {
    try {
      const response = await priceFilterRangeGetAPICall();
      if (response.status_code === 200) {
        setPriceRangeData(response.data);
        setMinPrice(response.data.min_price_amount);
        setMaxPrice(response.data.max_price_amount);
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const handleWishList = async (productId) => {
    const wishListResponse = await addToWishList(token, productId);
    if (wishListResponse.status === 401) {
      toast.error(wishListResponse.message);
    } else if (wishListResponse === 403) {
      toast.info("Product Already in wishlist");
    } else if (wishListResponse.status_code === 200) {
      toast.success(wishListResponse.message);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts =
    products !== null
      ? products.slice(indexOfFirstProduct, indexOfLastProduct)
      : [];

  return (
    <>
      <section className="section_breadcrumb section_padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home /</Link>
                </li>
                <li>
                  <Link>Shop</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="section_p_f_fil_cont section_padding_py">
        <div className="container">
          <div className="row s_p_f_f_c_par">
            <ProductFilter
              categoriesOptions={categoriesOptions}
              brandOptions={brandOptions}
              categoriesOnchange={categoriesOnchange}
              brandsOnchange={brandsOnchange}
              selectedCategory={selectedCategory}
              selectedBrand={selectedBrand}
              priceRangeData={priceRangeData}
              priceFilterOnchange={priceFilterOnchange}
              productID={productID}
            />

            <div className="col-lg-9 s_p_f_f_c_right">
              <div>
                <ProductSort
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                />
                <div className="row m-0 prdct_card_par">
                  {isLoading ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="loader"
                          style={{ width: "200px", height: "200px" }}
                        >
                          <Hourglass color="#00BFFF" height={200} width={200} />
                        </div>
                      </div>
                      <h2 style={{ textAlign: "center" }}>Loading......</h2>
                    </>
                  ) : (
                    currentProducts &&
                    currentProducts.length > 0 &&
                    currentProducts.map((product) => (
                      <div
                        className="wrapper dy_product-w-p col-md-3"
                        key={product.id}
                      >
                        <div className="product">
                          <figure className="product-media">
                            <a
                              onClick={() =>
                                singlePageRedirection(product.product_id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={
                                  product.thumnail_image.endsWith(
                                    "undefined"
                                  ) || product.thumnail_image === ""
                                    ? noImage
                                    : AppUrl + product.thumnail_image
                                }
                                alt={product.product_name}
                                width="280"
                                height="315"
                              />
                            </a>
                            <div className="product-action-vertical">
                              <div
                                key={product.product_id}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  singlePageRedirection(product.product_id);
                                }}
                                className="btn-product-icon btn-wishlist"
                                title="Add to cart"
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon
                                  icon={faCartShopping}
                                  style={{ color: "#ea2a3d" }}
                                />
                              </div>
                              <a
                                onClick={() =>
                                  handleWishList(product.product_id)
                                }
                                class="btn-product-icon btn-wishlist"
                                title="Add to wishlist"
                              >
                                {/* <i className="bi bi-heart" style={{ color: 'black' }}></i> */}
                                <FontAwesomeIcon
                                  icon={faHeart}
                                  style={{ color: "#e42f2f" }}
                                />
                              </a>
                            </div>

                            <div className="product-action">
                              <a
                                className="quickview-button "
                                title="Quick View"
                                onClick={(e) => {
                                  e.preventDefault();
                                  singlePageRedirection(product.product_id);
                                }}
                                href="df"
                                style={{ textDecoration: "none" }}
                              >
                                Quick View
                              </a>
                            </div>
                          </figure>
                          <div className="product-details">
                            <div className="product-cat">
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "grey",
                                }}
                                href="#"
                              >
                                {" "}
                                {product.brand_name}{" "}
                              </a>
                            </div>
                            <h3 className="product-name">
                              <a
                                onClick={() =>
                                  singlePageRedirection(product.product_id)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {product.product_name}
                              </a>
                            </h3>
                            <div className="product-price">
                              <ins className="new-price">
                                Rs.{" "}
                                {product.discount_percentage !== 0
                                  ? product.discount_price
                                  : product.original_price}
                              </ins>
                              {product.discount_percentage !== 0 && (
                                <del className="old-price">
                                  Rs.{product.original_price}
                                </del>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                {!noMatchFound && (
                  <PaginationIndex
                    totalProducts={products?.length}
                    productsPerPage={productsPerPage}
                    currentPage={currentPage}
                    gotoFirstPage={gotoFirstPage}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                  />
                )}
              </div>
              {!isLoading && noMatchFound && (
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  No match found...
                </div>
              )}
            </div>
          </div>
          {selectedProduct && (
            <ModalProduct
              showModal={showModal}
              handleCloseModal={handleCloseModal}
              selectedProduct={selectedProduct}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default CategoryPage;
