import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../service/apiService";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      const response = await changePassword(token, newPassword);
      if (response.status_code === 200) {
        toast.success("Password changed successfully!");
        localStorage.removeItem("token");
        navigate("/login");

        setNewPassword("");
        setConfirmPassword("");
      }
      console.log(response, "response");
    } else {
      toast.error("Passwords do not match!");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container mt-5 ">
          <div className="row   justify-content-center ">
            <div className="card p-4 col-lg-6 shadow-lg border-0">
              <div className="mb-4">
                <label
                  htmlFor="newPassword"
                  className="form-label fs-5  text-black"
                >
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control fs-5 border border-primary rounded-3"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirmPassword"
                  className="form-label fs-5 text-black"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control fs-5 border border-primary rounded-3"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="btn btn-primary fs-5 px-5 py-3 mb-5"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
