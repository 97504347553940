import React from "react";
// import '../../assets/css/loader.scss'

const Loader = () => {
  return (
    <div className="center-container">
    <div className="loader-container">
      <div className="loader-top">
        <div className="loader-top-line"></div>
      </div>
      <div className="loader-neck"></div>
      <div className="loader-body">
        <div className="loader-content">
          <div className="blob-group">
            <div className="blob-item-one"></div>
            <div className="blob-item-two"></div>
            <div className="blob-item-three"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Loader;
