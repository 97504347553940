import React, { useState, useEffect } from "react";
import feather from "feather-icons";
import {
    faqCategoriesDropdown,
    faqCategorieFilter,
} from "../../../service/apiService";
import bgImage from '../../../assert/image/HeaderBG.jpg'

const Faq = () => {
    window.scrollTo({
        top: 0,
    });
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [activeFaq, setActiveFaq] = useState(true); // To track the active FAQ
    const [faqCategories, setFaqCategories] = useState([]);
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        feather.replace();
        getAllFaqList();
    }, []);

    const getAllFaqList = async () => {
        try {
            const response = await faqCategoriesDropdown();
            setFaqCategories(response.data);

            if (response.data.length > 0) {
                // Load FAQs for the first category by default
                const defaultCategory = response.data[0];
                setSelectedCategory(defaultCategory.id);
                const faqs = await getFilterFaqList(defaultCategory.id);

                // Set the first FAQ as the active FAQ by default
                if (faqs.length > 0) {
                    setActiveFaq(faqs[0]);
                }
            }
        } catch (error) {
            console.error("Error fetching FAQ categories", error);
        }
    };

    const getFilterFaqList = async (id) => {
        try {
            setFilterList([]); // Clear the filter list before fetching new data
            const response = await faqCategorieFilter(id);
            setFilterList(response.data);
            setSelectedCategory(id);

            // Set the first FAQ of the selected category as the active FAQ
            if (response.data.length > 0) {
                setActiveFaq(response.data[0]);
            } else {
                setActiveFaq(null); // Clear the active FAQ if no data is returned
            }
        } catch (error) {
            console.error("Error fetching filtered FAQs", error);
        }
    };

    const handleAccordionClick = (faq) => {
        setActiveFaq(activeFaq === faq ? null : faq); // Toggle active FAQ
    };

    return (
        <section className="sec_faq">
            <div className="page-wrapper">
                <main className="main">
                    <nav className="breadcrumb-nav">
                        <div className="container">
                            <ul className="breadcrumb">
                                <li>
                                    <a href="demo1.html">
                                        <i className="d-icon-home" />
                                    </a>
                                </li>
                                <li>FAQs</li>
                            </ul>
                        </div>
                    </nav>
                    <div
                        className="page-header"
                        style={{ backgroundImage: `url(${bgImage})` }}
                    >
                        <h1 className="page-title font-weight-bold text-capitalize lh-1 text-center">
                            Asked Questions
                        </h1>
                    </div>
                    <div className="page-content mb-10 pb-8">
                        <section>
                            <div
                                className="container"
                                style={{ marginTop: "100px" }}
                            >
                                <div className="row">
                                    {/* Left Column for FAQ Categories */}
                                    <div className="col-lg-4 mb-5 mb-lg-0">
                                        <div className="faq-categories">
                                            {faqCategories?.map((category) => (
                                                <h5
                                                    key={category.id}
                                                    className={`faq-title pl-2 pr-2 ls-m text-left ${
                                                        selectedCategory ===
                                                        category.id
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        getFilterFaqList(
                                                            category.id
                                                        )
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {category.name}
                                                </h5>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Right Column for FAQ Titles and Descriptions */}
                                    <div className="col-lg-8">
                                        <div className="accordion accordion-border accordion-boxed accordion-plus">
                                            {filterList?.length > 0 ? (
                                                filterList?.map(
                                                    (faq, faqIndex) => (
                                                        <div
                                                            className="card"
                                                            key={faqIndex}
                                                        >
                                                            <div
                                                                className="card-header"
                                                                onClick={() =>
                                                                    handleAccordionClick(
                                                                        faq
                                                                    )
                                                                }
                                                            >
                                                                <a
                                                                    href={`#collapse-${selectedCategory}-${faqIndex}`}
                                                                    className={
                                                                        "expand"
                                                                    }
                                                                    style={{
                                                                        textDecoration:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    {faq.title}
                                                                </a>
                                                            </div>
                                                            <div
                                                                id={`collapse-${selectedCategory}-${faqIndex}`}
                                                                className={
                                                                    activeFaq ===
                                                                    faq
                                                                        ? "expanded"
                                                                        : "collapsed"
                                                                }
                                                            >
                                                                <div className="card-body">
                                                                    <p>
                                                                        {
                                                                            faq.description
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <p>No Data</p> // Display "No Data" if no FAQs are found
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </section>
    );
};

export default Faq;
