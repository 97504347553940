import React from "react";
import "../../../assert/css/main.css";

const Section8 = ({ sections }) => {
  const desc = sections?.find((item) => item.key_name === "DESC")?.key_value;
  const desc_2 = sections?.find(
    (item) => item.key_name === "DESC_1"
  )?.key_value;
  const desc_3 = sections?.find(
    (item) => item.key_name === "DESC_2"
  )?.key_value;
  const desc_4 = sections?.find(
    (item) => item.key_name === "DESC_3"
  )?.key_value;
  const title = sections?.find((item) => item.key_name === "TITLE")?.key_value;
  const title_1 = sections?.find(
    (item) => item.key_name === "TITLE_1"
  )?.key_value;
  const title_2 = sections?.find(
    (item) => item.key_name === "TITLE_2"
  )?.key_value;
  const title_3 = sections?.find(
    (item) => item.key_name === "TITLE_3"
  )?.key_value;
  return (
    <section className="section8 section_padding">
      <div className="container-fluid">
        <div className="row">
          
            <div className="col-lg-3 px-0 p-4 bg_green border">
              <h4 className="icon-box-title text-center fw-semibold">
                {title}
              </h4>
              <p className="text-center">{desc}</p>
            </div>
            <div className="col-lg-3 px-0 p-4 bg_green border">
              <h4 className="icon-box-title text-center fw-semibold">
                {title_1}
              </h4>
              <p className="text-center">{desc_2}</p>
            </div>
            <div className="col-lg-3 px-0 p-4 bg_purple border">
              <h4 className="icon-box-title text-center fw-semibold">
                {title_2}
              </h4>
              <p className="text-center">{desc_3}</p>
            </div>
            <div className="col-lg-3 px-0 p-4 bg_purple border">
              <h4 className="icon-box-title text-center fw-semibold">
                {title_3}
              </h4>
              <p className="text-center">{desc_4}</p>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Section8;
