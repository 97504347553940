import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PaginationIndex = ({ totalProducts, productsPerPage, currentPage, handlePageChange, handleNextPage, handlePreviousPage,gotoFirstPage }) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  // const [currentPage, setCurrentPage] = useState(1);

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  //   // Add any additional logic for page change, e.g., fetching data
  // };

  // const renderPageNumbers = () => {
  //   let pageNumbers = [];
  //   const startPage = Math.max(currentPage - 5, 1);
  //   const endPage = Math.min(startPage + 9, totalPages);

  //   for (let i = startPage; i <= endPage; i++) {
  //     pageNumbers.push(
  //       <li key={i} className={i === currentPage ? "active" : ""}>
  //         <Link to={`/shop?page=${i}`} onClick={() => handlePageChange(i)}>
  //           {i}
  //         </Link>
  //       </li>
  //     );
  //   }

  //   return pageNumbers;
  // };

  return (
    <div className="row pgntn_par">

      <div className="d-flex align-items-center justify-content-center" >
        <div className="d-flex align-items-center me-3 " role="button"  onClick={gotoFirstPage}>
        <FontAwesomeIcon icon={faAngleDoubleLeft} size='sm' color="	#A8A8A8"/>
          <span className="text-secondary ps-2">page 1</span>
        </div>
        <div className="page_number">
          <Link
            // to={`/shop?page=${currentPage - 1}`}
            className="prev"
            onClick={handlePreviousPage}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className="ps-2">Previous</span>
          </Link>
          <div >
            <div className="page_count">
              Page {currentPage} of {totalPages}
            </div>
          </div>
          <Link
            // to={`/shop?page=${currentPage + 1}`}
            className="next"
            onClick={handleNextPage}>
            <span className="pe-2">Next</span>
            <FontAwesomeIcon icon={faChevronRight} />

          </Link>
        </div>
      </div>

    </div>
  );
};

export default PaginationIndex;
