import React from "react";
import Slider from "react-slick";
import noLogo from "../../../assert/image/Nologo.svg.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section7 = ({ sections }) => {
  const title = sections?.find((item) => item.key_name === "TITLE")?.key_value;
  const BrandsData = sections?.find(
    (item) => item.key_name === "BRAND"
  )?.brands_list;
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const totalData = BrandsData.filter((item) => item.brand_image !== "");
  return (
    <section className="brands section_padding">
      <div className="title-wrapper title-cross mb-2">
        <h2 className="title">{title}</h2>
      </div>
      <Slider {...settings} className="brand-carousel">
        {totalData?.map((brand) => (
          <div key={brand.id} className="brand-item">
            <img
              src={brand.brand_image === null ? "" : AppUrl + brand.brand_image}
              alt={brand.brand_name}
              width={180}
              height={100}
            />
            <p className="brand-name mb-0">{brand.brand_name}</p>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Section7;
