import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { fetchUserData, logoutService } from "../../service/apiService";
import '../../assert/css/myprofile.css'

const OrderSidebar = () => {
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const tokenCookie = localStorage.getItem("token");
  const getUserDatails = async () => {
    try {
      const response = await fetchUserData(tokenCookie);
      setUserDetails(response.data);
    } catch (error) {}
  };
  const handleLogout = async () => {
    try {
      if (tokenCookie) {
        const logoutResponse = await logoutService(tokenCookie);
        if (logoutResponse.status_code === 200) {
          localStorage.removeItem("token");
          navigate("/");
          toast.success("User logging out");
        }
      } else {
        throw new Error("Token not found");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  useEffect(() => {
    getUserDatails();
  }, []);

  return (
    <aside className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
      <div className=" account-sidebar-wrapper">
        <div
          className="account-sidebar mt-5"
          style={{ width: "100%", height: "80vh" }}
          id="accountSidebar"
        >
          <div className="profile-head">
            <h5 className="title mb-0">{userDetails.full_name}</h5>
            <span className="text text-primary">{userDetails.email}</span>
          </div>
          <hr />
          <div className="d-flex ms-5 custom-links ">
            <Link to="" className="link-hover">
              Order info
            </Link>
            <Link to="address-book" className="link-hover">
             Address Book
            </Link>
            <Link to="change-password" className="link-hover">
             Change Password
            </Link>
            <Link onClick={() => handleLogout()} className="link-hover">
              Logout
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default OrderSidebar;
