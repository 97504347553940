import React from "react";
import { Link } from "react-router-dom";
import PriceFilter from "./PriceFilter";

const ProductFilter = ({
  priceFilterOnchange,
  priceRangeData,
  categoriesOptions,
  brandOptions,
  categoriesOnchange,
  brandsOnchange,
  selectedCategory,
  selectedBrand,
  productID
}) => {
  return (
    <>
      <div className="col-lg-3 s_p_f_f_c_left">
        <div className="filter_cont">
          <h6 className="filter_line"></h6>

          <div className="sing_filter">
            <PriceFilter
              priceFilterOnchange={priceFilterOnchange}
              priceRangeData={priceRangeData}
            />
          </div>
          <div className="sing_filter">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="m-0">CATEGORIES</h6>
              <button
                className="clearFilterButton"
                onClick={() => categoriesOnchange(0)}
              >
                Clear
              </button>
            </div>
            <ul className="ultest">
              {categoriesOptions &&
                categoriesOptions.length > 0 &&
                categoriesOptions.map((category) => (
                  <li key={category.id} className="litest">
                    <input
                      id={category.category_name}
                      name="category"
                      type="radio"
                      className="shopradio"
                      checked={selectedCategory === category.id || productID === category.id}
                      onClick={() => categoriesOnchange(category.id)} 
                      disabled={!!productID} 
                    />
                    <label htmlFor={category.category_name}>
                      {category.category_name}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
          <div className="sing_filter has_more_btn">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="m-0">BRAND</h6>
              <button
                className="clearFilterButton"
                onClick={(e) => brandsOnchange(0)}
              >
                Clear
              </button>
            </div>
            <ul className="ultest" id="category">
              {brandOptions &&
                brandOptions.length > 0 &&
                brandOptions.map((brand) => (
                  <li className="litest" key={brand.id}>
                    <input
                      id={brand.brand_name}
                      name="brand"
                      type="radio"
                      className="shopradio"
                      checked={selectedBrand === brand.id}
                      onClick={(e) => brandsOnchange(brand.id)}
                    />
                    <label htmlFor={brand.brand_name}>{brand.brand_name}</label>
                  </li>
                ))}
            </ul>
            {/* <Link
              className="sing_filter_more_btn"
              data-value="#category"
              to="/"
            >
              + Show More
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductFilter;
