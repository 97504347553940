import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeart,
    faBagShopping,
    faMagnifyingGlass,
    faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { useNavigate } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assert/css/Section4.css";
import { addToWishList } from "../../../service/apiService";
import { toast } from "react-toastify";
const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const Section4 = ({ sections }) => {
    const title = sections?.find(
        (item) => item.key_name === "TITLE"
    )?.key_value;
    const ads = sections?.find((item) => item.key_name === "ADS")?.ads_data;
    const classification = sections?.find(
        (item) => item.key_name === "CLASSIFI"
    )?.classification_data;
    const AppUrl = process.env.REACT_APP_IMAGE_URL;
    const navigate = useNavigate();

    const singlePageRedirection = (id) => {
        navigate(`/product_detail`, { state: { id } });
    };
    const token = localStorage.getItem("token");

    const handleWishList = async (productId) => {
        const wishListResponse = await addToWishList(token, productId);
        if (wishListResponse.status === 401) {
            toast.error(wishListResponse.message);
        } else if (wishListResponse === 403) {
            toast.info("Product Already in wishlist");
        } else if (wishListResponse.status_code === 200) {
            toast.success(wishListResponse.message);
        }
    };

    const AddsToProductsPage = (url) => {
        if (url.includes("get-filter-products")) {
            const parts = url.split("/");
            const categoryUrlId =
                parts[parts.indexOf("get-filter-products") + 1];
            const brandsUrlId = parts[parts.indexOf("get-filter-products") + 2];
            const minPriceUrl = parts[parts.indexOf("get-filter-products") + 3];
            const maxPriceUrl = parts[parts.indexOf("get-filter-products") + 4];
            navigate("/shop", {
                state: { categoryUrlId, brandsUrlId, minPriceUrl, maxPriceUrl },
            });
        }
    };
    return (
        <section className="sec_home_4 product-wrapper section_padding">
            <div className="border_green py-5">
                <div className="title-wrapper title-cross">
                    <h2 className="title">{title}</h2>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <div class="banner banner-fixed banner-sale">
                                <figure>
                                    {ads[0]?.image_path.split(".").pop() ===
                                    "mp4" ? (
                                        <video
                                            src={AppUrl + ads[0]?.image_path}
                                            autoPlay
                                            width="100%"
                                            height={400}
                                            loop
                                        />
                                    ) : (
                                        <img
                                            src={AppUrl + ads[0]?.image_path}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                            alt="banner"
                                        />
                                    )}
                                </figure>
                                <div className="banner-content">
                                    <h4 className="banner-subtitle mb-2 text-uppercase text-white">
                                        {ads[0]?.name}
                                    </h4>
                                    <h3 className="banner-title text-white mb-4">
                                        {ads[0]?.title_one}
                                    </h3>
                                    <a
                                        onClick={() =>
                                            AddsToProductsPage(ads[0]?.link_one)
                                        }
                                        className="btn btn-md btn-link btn-underline btn-white"
                                    >
                                        Shop Now
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="ml-1"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 ">
                            <Slider {...settings}>
                                {classification?.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            margin: "0 10px",
                                        }}
                                    >
                                        <img
                                            className="product-image-"
                                            src={AppUrl + item.thumnail_image}
                                            alt={item.product_name}
                                            onClick={() =>
                                                singlePageRedirection(
                                                    item.product_id
                                                )
                                            }
                                            // className="banner-img"
                                            // style={{
                                            //   width: "100%",
                                            //   height: "300px",
                                            //   objectFit: "contain",
                                            // }}
                                        />

                                        <div className="product-details pb-0">
                                            <h3
                                                className="product-name"
                                                key={item.id}
                                                onClick={() =>
                                                    singlePageRedirection(
                                                        item.product_id
                                                    )
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                {item.product_name}
                                            </h3>
                                            <div className="product-price">
                                                {item.discount_price > 0 ? (
                                                    <>
                                                        <span className="discount-price">
                                                            &#8377;
                                                            {
                                                                item.discount_price
                                                            }
                                                        </span>
                                                        <span
                                                            className="original-price"
                                                            style={{
                                                                textDecoration:
                                                                    "line-through",
                                                                marginLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            &#8377;
                                                            {
                                                                item.original_price
                                                            }
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span className="price">
                                                        &#8377;
                                                        {item.original_price}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-evenly hover-container p-0">
                                                <a
                                                    className="btn-product btn-cart d-flex text-decoration-none"
                                                    data-toggle="modal"
                                                    data-target="#addCartModal"
                                                    title="Add to cart"
                                                    key={item.id}
                                                    onClick={() =>
                                                        singlePageRedirection(
                                                            item.product_id
                                                        )
                                                    }
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faBagShopping}
                                                        style={{
                                                            color: "#838891",
                                                        }}
                                                    />
                                                    Add to cart
                                                </a>
                                                <a
                                                    className="btn-product btn-cart d-flex"
                                                    title="Add to wishlist"
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                    onClick={() =>
                                                        handleWishList(item.id)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faHeart}
                                                        style={{
                                                            color: "#030303",
                                                        }}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section4;
