import "./assert/css/main.css";
import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setSettingsData } from "./redux/Slice/settingSlice";
import { SettingApiData } from "./service/apiService";
import Loading from "./pages/Loader/Loader";
import SubPages from "./pages/subPages/SubPages";
import Faq from "./pages/views/Faq/Faqpage";

import CheckoutPage from "./pages/views/Checkout/CheckoutPage";
import ProductMain from "./pages/Shop/ProductMain";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import CampageList from "./components/CampageList/CampageList";
import WishlistPage from "./pages/views/Wishlist/Wishlistpage";
import ForgotPasswordPage from "./components/ForgotPassword/ForgotPassword";
import OtpPage from "./components/OtpPage/OtpPage";
import ContactUs from "./components/ContactUs/ContactUs";
import Orders from "./components/Profile/Orders";
import OrdersList from "./components/Profile/OrdersList";
import OrderDetails from "./components/Profile/OrderDetails";
import AddressBook from "./components/Profile/AddressBook";
import ChangePassword from "./components/Profile/ChangePassword";
import Page from "./components/Page/Home/Page";
import CategoryPage from "./components/CategoryPage/CategoryPage";
const LoginPage = lazy(() => import("./components/Page/Home/Login/LoginPage"));
const Header = lazy(() => import("./components/Header/Header"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const Home = lazy(() => import("./components/Page/Home/Home"));
const Productdetail = lazy(() => import("./pages/Shop/Testproduct"));

const BlogsList = lazy(() => import("./pages/views/Blogs/BlogsList"));
const BlogsDetailsPage = lazy(() => import("./pages/views/Blogs/BlogDetails"));

function App() {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    getSettingData();
  }, []);
  const getSettingData = async () => {
    try {
      const setting = await SettingApiData();
      dispatch(setSettingsData(setting.data));
    } catch (error) {
      console.error("Error fetching settings data:", error);
    }
  };
  const settingsData = useSelector((state) => state.settings.settingsData);
  const siteLogo = settingsData.find(
    (setting) => setting.key_name === "FAV_ICON"
  );
  const siteName = settingsData.find(
    (setting) => setting.key_name === "SITE_NAME"
  );
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = AppUrl + siteLogo?.key_value;
  }, [siteLogo?.key_value]);
  useEffect(() => {
    document.title = siteName?.key_value;
  }, [siteName?.key_value]);
  const isHeaderAndFooterRequired = ![
    "/invoice",
    "/checkout/address",
    "/design",
    "/design/mens",
    "/design/womens",
    "/design/home-and-living",
    "/design/beauty",
    "/design/electronic",
    "/design/explore",
    "/otp-verify",
    "/checkout/preorder",
  ].includes(location.pathname);
  return (
    <div>
      {isHeaderAndFooterRequired && <Header />}
      <Suspense
        fallback={
          <div
            className="vh-100 d-flex justify-content-center"
            style={{ background: "#F3F5F0" }}
          >
            <Loading />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sub-pages" element={<SubPages />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/checkout/cart" element={<PrivateRoute><CheckoutPage /></PrivateRoute>} />
          <Route path="/shop" element={<ProductMain />} />
          <Route path="/shop/:categoryName" element={<CategoryPage />} />
          <Route path="/product_detail" element={<Productdetail />} />
          <Route
            path="/Wishlistpage"
            element={
              <PrivateRoute>
                <WishlistPage />
              </PrivateRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/campaign-list" element={<CampageList />} />
          <Route path="/otp" element={<OtpPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path='/blogs' element={<BlogsList />} />
          <Route path='/blogs-details' element={<BlogsDetailsPage />} />
          <Route path="/page/:slug" element={<Page />} />
          <Route path="/order-details" element={<Orders />}>
            <Route index element={<OrdersList />} />
            <Route  path="address-book" element={<AddressBook />} />
            <Route  path="change-password" element={<ChangePassword />} />
            <Route path="view" element={<OrderDetails />} />
            
          </Route>
        </Routes>
      </Suspense>
      <ToastContainer
        toastStyle={{
          fontSize: "15px",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#F6A709",
          borderRadius: "5px",
          padding: "10px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
          width: "800px",
          maxWidth: "100%",
          margin: "0 auto",
          textAlign: "center",
          height: "80px",
          minHeight: "80px",
        }}
        toastClassName="custom-toast"
      />
      {isHeaderAndFooterRequired && (
        <Suspense fallback={""}>
          <Footer />
        </Suspense>
      )}
    </div>
  );
}
export default App;
