import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { forgetPassword } from "../../service/apiService";

const ForgotPasswordPage = () => {
  const settingsData = useSelector((state) => state.settings.settingsData);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setIsLoading(true);
    try {
      document.getElementById("sendpasswordlink").disabled = true;
      const response = await forgetPassword(email);
      setIsLoading(false);
      if (response.status_code === 200) {
        setSuccessMessage(
          "Temporary password sent successfully and check your email"
        );
        setTimeout(() => {
          setSuccessMessage("");
          navigate("/login");
          document.getElementById("sendpasswordlink").disabled = false;
        }, 7000);
      }
    } catch (error) {
      document.getElementById("sendpasswordlink").disabled = false;
      setError(
        "An error occurred while sending the password reset link. Please try again."
      );
      console.error("Error during API call:", error);
      setIsLoading(false);
    }
  };
  const loginLogo = settingsData.find(
    (setting) => setting.key_name === "LOGIN_PAGE_IMG"
  );
  let loginPageImg = "";

  if (loginLogo && loginLogo.key_value) {
    loginPageImg = AppUrl + loginLogo.key_value;
  }

  return (
    
      <div className="container-fluid">
        <div
          className="login-container row d-flex justify-content-center py-5"
          style={{ backgroundColor: "#ffe6e6",  minHheight:'60vh' }}
        >
          <div className="login-form col-md-5 col-12 d-flex flex-column align-items-center justify-content-center  ">
            <img src={loginPageImg} alt="Login" className="login-image mb-4" />
            <div className="form-wrapper p-4">
              {isLoading && (
                <div className="loading-message">
                  Email Sending Please Wait...
                </div>
              )}
              <p className="form-title text-center fs-2">
                <b>Forgot Password</b>
              </p>
              <p className="form-description text-center">
                Lost your password? Please enter your username or email address.
                You will receive a link to create a new password via email.
              </p>
              {error && <div className="alert text-danger alert-danger">{error}</div>}
              {successMessage && (
                <div className="alert alert-success  ">{successMessage}</div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="form-group d-flex flex-column align-items-center   mb-3">
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter Your Email"
                    className="form-control fs-4 w-75 "
                    onChange={handleInputChange}
                    value={email}
                  />
                  <div className="d-flex justify-content-center">
                    <button
                      id="sendpasswordlink"
                      className="btn mt-3 py-3 px-5 w-100 fs-4"
                      style={{ background: "#ff0066", color: "white" }}
                    >
                      Send Password Reset Link
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

  );
};

export default ForgotPasswordPage;
