import React from "react";
import Slider from "react-slick";

// Slick Slider styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useNavigate } from "react-router-dom";

const Section9 = ({ sections }) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const classfi = sections?.find(
    (item) => item.key_name === "CLASSIFI"
  )?.classification_data;
  const groupedProducts = [];
  for (let i = 0; i < classfi?.length; i += 3) {
    groupedProducts.push(classfi?.slice(i, i + 3));
  }
  const navigate = useNavigate();

  const singlePageRedirection = (id) => {
    navigate(`/product_detail`, { state: { id } });
  };
  const title = sections?.find((item) => item.key_name === "TITLE")?.key_value;
  console.log("title", sections);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 500,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="prod_card_sec appear-animate section_padding">
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="widget widget-products">
            <h4 className="rect-title section-title fs-1 mb-4">{title}</h4>
            <div className="products-col">
              <Slider {...settings}>
                {groupedProducts?.map((group, index) => (
                  <div key={index} className="border-right">
                    {group?.map((section, subIndex) => (
                      <div
                        key={subIndex}
                        className="product product-list-sm mb-4"
                      >
                        <figure className="product-media">
                          <a>
                            <img
                              src={AppUrl + section.thumnail_image}
                              alt={section.name}
                              width={100}
                              height={100}
                              onClick={() =>
                                singlePageRedirection(section.product_id)
                              }
                            />
                          </a>
                        </figure>
                        <div className="product-details">
                          <h3
                            className="product-name"
                            key={section.id}
                            onClick={() =>
                              singlePageRedirection(section.product_id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {section.product_name}
                          </h3>
                          <div className="product-price">
                            {section.discount_price > 0 ? (
                              <>
                                <span className="discount-price">
                                  &#8377;{section.discount_price}
                                </span>
                                <span
                                  className="original-price"
                                  style={{
                                    textDecoration: "line-through",
                                    marginLeft: "10px",
                                  }}
                                >
                                  &#8377;{section.original_price}
                                </span>
                              </>
                            ) : (
                              <span className="price">
                                &#8377;{section.original_price}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section9;
