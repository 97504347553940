import React from "react";

const ProductSort = ({ sortValue, setSortValue }) => {
  const handleSortChange = (event) => {
    setSortValue(event.target.value);
  };

  return (
    <div className="d-flex justify-content-end me-3">
      <p className="mb-0">Sort By:</p>
      <select
        className="ms-2  form-select col-lg-2"
        value={sortValue}
        onChange={handleSortChange}
      >
        <option className="text-uppercase" value="asc">Ascending</option>
        <option className="text-uppercase" value="desc">Descending</option>
        <option className="text-uppercase" value="price_asc">Price - Ascending</option>
        <option className="text-uppercase" value="price_desc">Price - Descending</option>
      </select>
    </div>
  );
};

export default ProductSort;
