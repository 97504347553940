import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import emptyImage from '../../../assert/image/emptycart.jpg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import { removeWishList, userWishList } from "../../../service/apiService";
import "../../../assert/css/Wishlistpage.css";
import { toast } from "react-toastify";

const WishlistPage = () => {
  window.scrollTo({
    top: 0
  });
  const isLoggedIn = true;
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  let token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [wishListData, setwishListData] = useState([]);

  useEffect(() => {
    fetchWishList();
  }, []);

  const fetchWishList = async () => {
    if (token) {
      const wishListData = await userWishList(token);
      setwishListData(wishListData.data);
    }
  };


  const wishListRemove = async (productId) => {
    try {
      const response = await removeWishList(productId, token);
      if (response.status_code === 200) {
        toast.success("Item removed successfully");
        fetchWishList();
      }
    } catch (error) { }
  };


  const handleProductClick = (id) => {
    navigate(`/product_detail`, { state: { id } });
  };

  return (
    <div className="wishlist-container text-center">
      {isLoggedIn ? (
        <main>
          <section className="wishlist-section">
            <div className="container">
              {wishListData && wishListData.length > 0 ? (
                <table className="wishlist-table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Product Name</th>
                      <th>Status</th>
                      <th>Price</th>
                      <th>Actions</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wishListData.map((product) => (
                      <tr key={product.product_id}>
                        <td>
                          <div
                            className="wishlist-image"
                            onClick={() => handleProductClick(product.product_id)}
                            style={{ cursor: 'pointer' }}
                          >
                            <img src={product.thumnail_image ? AppUrl + product.thumnail_image : emptyImage} alt={product.product_name} />
                          </div>
                        </td>
                        <td style={{ color: 'grey' }}>{product.product_name}</td>

                        <td>{product.description}</td>
                        <td>
                          <div className="price">
                            <span className="discount-price">
                              <strong>{product.original_price} ₹
                              </strong> </span>
                            <span className="original-price">{product.originalPrice}</span>
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary-cart"
                            onClick={() => handleProductClick(product.product_id)}
                            style={{ cursor: 'pointer' }}
                          >
                            Add to Cart
                          </button>
                        </td>
                        <td
                          className="product-remove"
                          onClick={() => wishListRemove(product.product_id)}
                        >
                          <button className="btn btn-danger" type="button">
                            <FontAwesomeIcon icon={faTrashCan} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="empty-wishlist">
                  <h3>Your Wishlist is Empty!</h3>
                  <img src={emptyImage} alt="empty" className="empty-image" />
                </div>
              )}
            </div>
          </section>
        </main>
      ) : (
        <div className="login-prompt">
          <p className="login-message">PLEASE LOG IN</p>
          <p className="login-submessage">Login to view items in your wishlist.</p>
          <img
            src="https://constant.myntassets.com/checkout/assets/img/empty-bag.png"
            alt="Wishlist"
            className="login-image"
          />
          <button
            className="btn btn-login"
            onClick={() => navigate("/login")}
          >
            <b>LOGIN</b>
          </button>
        </div>
      )}
    </div>
  );
};

export default WishlistPage;
