import React from "react";

import { Outlet } from "react-router-dom";
import OrderSidebar from "./OrderSidebar";


const Orders = () => {
  return (
    <div className="content-inner-1 mt-4">
      <div className="container">
        <div className="row">
          <OrderSidebar />
          <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9 account-wrapper">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
