import React from "react";
import image from '../../../assert/image/5-2.jpg';
import { useNavigate } from "react-router";


const Section13 = ({ sections }) => {
	const navigate = useNavigate();
	const AppUrl = process.env.REACT_APP_IMAGE_URL;
	const ads = sections?.find((item) => item.key_name === "ADS")?.ads_data;
	const title = sections?.find((item) => item.key_name === "TITLE")?.key_value;
	const title_1 = sections?.find((item) => item.key_name === "TITLE_1")?.key_value;
	const DESC_1 = sections?.find((item) => item.key_name === "DESC_1")?.key_value;
	const DESC_2 = sections?.find((item) => item.key_name === "DESC_2")?.key_value;
	return (
		<section className="store-section pb-10 appear-animate section_padding">
			<h1 className="section-title">{title}</h1>
			<div className="container">
				<div className="row align-items-center text-center text-lg-start">
					<div className="col-lg-6 order-md-first mb-4">
						<h5 className="section-subtitle lh-2 ls-md font-weight-normal mb-1 text-center">
							{title_1}
						</h5>
						<h3 className="section-title lh-1 font-weight-bold">
							{DESC_1}
						</h3>
						<p className="section-desc text-grey text-center">
							{DESC_2}
						</p>
					</div>
					<div className="col-lg-6 mb-4" onClick={() => navigate(ads[0]?.link_one)}>
						<figure>
							<img
								src={AppUrl + ads[0]?.image_path}
								alt="Our Store"
								width={580}
								height={507}
								className="banner-radius"
								style={{ backgroundColor: "#DEE6E8" }}
							/>
						</figure>
					</div>
				</div>
			</div>
		</section>

	);
}
export default Section13;